import Logo from "./Logo/index";
import "./index.css";

function OnboardingHeader(props) {
  return (
    <div className="header left-side right-side">
      <div className="left-side-text">
        <Logo></Logo>
      </div>
      <div className=""></div>
      {props.screenNumber !== undefined && (
        <div className="right-side-text">
          <p className="signup-form-step-1">step {props.screenNumber}/4</p>
        </div>
      )}
    </div>
  );
}

export default OnboardingHeader;
