/** @format */

import "./index.css";

function UpgradePlan(props) {
  let nextPlanText;
  let upgradeLinkText;

  const isCustomPlan = props.customPlan;

  nextPlanText = isCustomPlan
    ? "Customize your plan"
    : "Upgrade to " +
      props.nextPlan +
      " for " +
      props.nextPlanAuditCount +
      " audits per week";
  upgradeLinkText = isCustomPlan
    ? "Book a Demo"
    : "Upgrade to " + props.nextPlan;

  return (
    <div
      className={props.auditCountExceeded ? "upgrade-div-error" : "upgrade-div"}
    >
      <div className="current-plan-info">
        <span>You are currently on the </span>
        <span>
          <b>{props.currentPlan} Plan, </b>{" "}
        </span>
        <span>which includes </span>
        <span>{props.currentPlanAuditCount} </span>
        <span>audits per week</span>
      </div>
      <div className="next-plan">
        <p>{nextPlanText}</p>
      </div>
      <div className="upgrade-link-container">
        {isCustomPlan ? (
          <a
            class={"upgrade-to-link"}
            href={"https://calendly.com/seoradar-clay/15min"}
            target="_blank"
            rel="noreferrer"
          >
            {upgradeLinkText}
          </a>
        ) : (
          <button
            class={"upgrade-to-link"}
            onClick={() => props.handleUpgradeLevel()}
          >
            {upgradeLinkText}
          </button>
        )}
      </div>
    </div>
  );
}

export default UpgradePlan;
