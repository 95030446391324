/** @format */
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
import "./index.css";
import React, { useState, useEffect } from "react";
import strings from "../../strings";

const { httpHeaderKeys, httpMethods, httpHeaderValues } = strings;

function PayoffPage(props) {
  const [seoradarServiceLevelListNew, setSeoradarServiceLevelList] = useState();
  const [nextPlan, setNextPlan] = useState(props.nextPlan);
  const [nextPlanServiceId, setNextPlanServiceId] = useState(
    props.nextPlanServiceId
  );
  const [goToDashboardBtnDisabled, setGoToDashboardBtnDisabled] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
    myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
    myHeaders.append(httpHeaderKeys.CLIENT, props.client);
    myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
    myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
    myHeaders.append(httpHeaderKeys.UID, props.uid);

    var requestOptions = {
      method: httpMethods.GET,
      headers: myHeaders,
    };
    const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/accounts/list_of_service_levels`;

    fetch(apiEndPoint, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        const resultJson = JSON.parse(result);
        let seoradarServiceLevelList = resultJson.message;
        setSeoradarServiceLevelList(seoradarServiceLevelList[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpgradeLevel = () => {
    let UpgradePlan;
    let UpgradePlanIndex;

    let list = Object.values(seoradarServiceLevelListNew);
    let newPlanIndex = 0;
    let keys = Object.keys(seoradarServiceLevelListNew);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === nextPlan) {
        newPlanIndex = i;
      }
    }

    if (nextPlan !== keys[keys.length - 1]) {
      if (list.length > newPlanIndex + 2 && list.length !== newPlanIndex + 1) {
        UpgradePlan = list[newPlanIndex + 1];
        UpgradePlanIndex = newPlanIndex + 1;
      } else {
        UpgradePlan = list[newPlanIndex + 1];
        UpgradePlanIndex = newPlanIndex + 1;
      }

      let upgradeServiceLevelName = keys[UpgradePlanIndex];
      let upgradeServiceLevelId = UpgradePlan.service_level_id;

      var myHeaders = new Headers();
      myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
      myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
      myHeaders.append(httpHeaderKeys.CLIENT, props.client);
      myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
      myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
      myHeaders.append(httpHeaderKeys.UID, props.uid);

      var requestBody = JSON.stringify({
        service_level_name: nextPlan,
        service_level_id: nextPlanServiceId,
      });

      var requestOptions = {
        method: httpMethods.POST,
        headers: myHeaders,
        body: requestBody,
      };
      const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/accounts/upgrade_service_level`;
      fetch(apiEndPoint, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(async (result) => {
          const resultJson = JSON.parse(result);
          let message = resultJson.message;
          if (message === "Succesfully upgraded the service level!") {
            setNextPlan(upgradeServiceLevelName);
            setNextPlanServiceId(upgradeServiceLevelId);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const redirectToDashboard = () => {
    setGoToDashboardBtnDisabled(true);
    window.location.href = process.env.REACT_APP_API_URI;
  };

  return (
    <div>
      <Header></Header>
      <div>
        <div className="invalid-name-img-container">
          <picture>
            <source
              media="(min-width:769px)"
              srcset="/images/payoff-main-desktop.png"
            ></source>
            <img
              alt="payoff"
              className="payoff-main"
              src="/images/payoff-main-mobile.png"
            ></img>
          </picture>
        </div>
        <div className="all-set-container">
          <p className="all-set-text">You’re All Set!</p>
        </div>
        <div className="description-container">
          <div className="description-left"></div>
          <div className="description-middle">
            <p className="description-text-1">Site monitoring begins now.</p>
            <p className="description-text-2">
              You’ll receive a report once your first audit is complete.
            </p>
          </div>
          <div className="description-right"></div>
        </div>
        <div className="go-to-dashboard-container-mobile">
          <button
            disabled={goToDashboardBtnDisabled}
            className="go-to-dashboard-btn"
            onClick={redirectToDashboard}
          >
            MY DASHBOARD
          </button>
        </div>
        <div className="customize-seo-monitoring-container">
          <p className="customize-seo-monitoring-text">
            Customize Your SEO Monitoring
          </p>
        </div>
        <div className="customization-points-container">
          <div className="customization-group-1">
            <div className="customization-point customization-point-1">
              <picture>
                <source
                  media="(min-width:769px)"
                  srcset="/images/purple-tick.svg"
                ></source>
                <img
                  alt="tick-mark"
                  className="tick-mark-1"
                  src="/images/small-purple-tick.png"
                ></img>
              </picture>
              <p className="customization-text customization-text-1">
                User Agents
              </p>
            </div>
            <div className="customization-point customization-point-2">
              <picture>
                <source
                  media="(min-width:769px)"
                  srcset="/images/purple-tick.svg"
                ></source>
                <img
                  alt="tick-mark"
                  className="tick-mark"
                  src="/images/small-purple-tick.png"
                ></img>
              </picture>
              <p className="customization-text customization-text-2">
                Organize URLs in page groups
              </p>
            </div>
            <div className="customization-point customization-point-3">
              <picture>
                <source
                  media="(min-width:769px)"
                  srcset="/images/purple-tick.svg"
                ></source>
                <img
                  alt="tick-mark"
                  className="tick-mark"
                  src="/images/small-purple-tick.png"
                ></img>
              </picture>
              <p className="customization-text customization-text-3">
                Run Audits on demand or scheduled
              </p>
            </div>
            <div className="customization-point customization-point-4">
              <picture>
                <source
                  media="(min-width:769px)"
                  srcset="/images/purple-tick.svg"
                ></source>
                <img
                  alt="tick-mark"
                  className="tick-mark"
                  src="/images/small-purple-tick.png"
                ></img>
              </picture>
              <p className="customization-text customization-text-4">
                Custom tests
              </p>
            </div>
          </div>
          <div className="customization-group-2">
            <div className="customization-point customization-point-5">
              <picture>
                <source
                  media="(min-width:769px)"
                  srcset="/images/purple-tick.svg"
                ></source>
                <img
                  alt="tick-mark"
                  className="tick-mark-2"
                  src="/images/small-purple-tick.png"
                ></img>
              </picture>
              <p className="customization-text">Authentication Methods</p>
            </div>
            <p className="customization-bullet-point">• Static IPs</p>
            <p className="customization-bullet-point">• HTTP authentication</p>
            <p className="customization-bullet-point">• Custom user agents</p>
            <p className="customization-bullet-point">• Custom headers</p>
            <p className="customization-bullet-point">• API public key</p>
          </div>
          <div className="customization-group-3">
            <div className="customization-point customization-point-6">
              <picture>
                <source
                  media="(min-width:769px)"
                  srcset="/images/purple-tick.svg"
                ></source>
                <img
                  alt="tick-mark"
                  className="tick-mark tick-mark-3"
                  src="/images/small-purple-tick.png"
                ></img>
              </picture>
              <p className="customization-text">Compare URLs 5 ways</p>
            </div>
            <p className="customization-bullet-point">
              • Fetched vs Fetched (standard)
            </p>
            <p className="customization-bullet-point">• Fetched vs Rendered</p>
            <p className="customization-bullet-point">
              • Rendered vs. Rendered
            </p>
            <p className="customization-bullet-point">• Mobile vs. Desktop</p>
            <p className="customization-bullet-point">
              • Staging vs. Live{" "}
              {props.svlEnabled === false ? (
                <button
                  className="upgrade-btn"
                  onClick={() => handleUpgradeLevel()}
                >
                  UPGRADE{" "}
                </button>
              ) : null}
            </p>
          </div>
        </div>
        <div className="go-to-dashboard-container-desktop">
          <button
            disabled={goToDashboardBtnDisabled}
            className="go-to-dashboard-btn"
            onClick={redirectToDashboard}
            type="button"
          >
            MY DASHBOARD
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default PayoffPage;
