/** @format */
import "./index.css";
import React, { useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import Chip from "../common/CustomizedChip";
import strings from "../../strings";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
import ScheduleOnboardingPage from "../ScheduleOnboardingPage";

const {
  manualAddUrlsPage,
  noMsg,
  validDomainRegEx,
  httpHeaderKeys,
  seoradarServiceLevels,
  httpMethods,
  httpHeaderValues,
} = strings;

const ManualAddUrls = (props) => {
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState(noMsg);
  const [errors, setError] = useState(noMsg);
  const [invalidUrls, setInvalidUrls] = useState([]);
  const [schedulePageFlag, setSchedulePageFlag] = useState(false);
  const [saveUrlsBtnDisabled, setSaveUrlsBtnDisabled] = useState(false);
  let pattern = new RegExp(validDomainRegEx, "i");

  const onDeleteFunc = (option) => {
    let filteredValues =
      value && value.length !== 0 ? value.filter((x) => x !== option) : [];
    let filteredInvalidValues =
      invalidUrls && invalidUrls.length !== 0
        ? invalidUrls.filter((x) => x !== option)
        : [];
    setValue(filteredValues);
    setInvalidUrls(filteredInvalidValues);
  };

  const sortListOfUrls = (listOfUrls) => {
    // let validUrls = listOfUrls.filter(function (keyword, index) {
    //   return !!pattern.test(keyword);
    // });

    let domainUrl = /https?:\/\//.test(props.domainUrl) ? props.domainUrl : `http://${props.domainUrl}`
    let domain = new URL(domainUrl);

    let urls = listOfUrls.filter(function (keyword, index) {
      let url = null;

      try {
        url = new URL(/https?:\/\//.test(keyword) ? keyword : `http://${keyword}`);
      } catch (TypeError) {
        // toast.error(`Please enter valid URLs.`, {duration: 5000});
        return true;
      }

      if (url.hostname.endsWith(`.${domain.hostname}`) && !props.allowSubdomainUrls) {
        toast.error(`Please enter URLs that belong to ${domain.hostname}. After onboarding, you can add a separate audit for each subdomain.`, { duration: 10000 });
        return false;

      } else if (url.hostname !== domain.hostname) {
        toast.error(`URLs must match the domain entered (${domain.hostname}).`, { duration: 10000 });
        return false;

      } else return true;
    });

    let validUrls = urls.filter(function (keyword, index) {
      return !!pattern.test(keyword);
    });

    let inValidUrls = urls.filter(function (keyword, index) {
      return !pattern.test(keyword);
    });

    setInvalidUrls(inValidUrls);
    let filteredAndSortedUrls = inValidUrls.concat(validUrls);
    return filteredAndSortedUrls;
  };

  const handleOnClick = () => {
    if (value.length === 0) {
      setError(manualAddUrlsPage.PLEASE_ENTER_VALID_URL_ERROR);
    } else if (invalidUrls.length > 0) {
      setError(manualAddUrlsPage.PLEASE_REMOVE_INVALID_URLS);
    } else {
      setError(noMsg);
      manualAddUrls();
    }
  };

  const manualAddUrls = () => {
    if (!errors) {
      // disable the button so that no more API Calls can be made
      setSaveUrlsBtnDisabled(true);
      var myHeaders = new Headers();
      myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
      myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
      myHeaders.append(httpHeaderKeys.CLIENT, props.client);
      myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
      myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
      myHeaders.append(httpHeaderKeys.UID, props.uid);

      var requestBody = JSON.stringify({ batch_urls: value });

      var requestOptions = {
        method: httpMethods.POST,
        headers: myHeaders,
        body: requestBody,
      };

      const { websiteId } = props;
      const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/websites/${websiteId}/add_urls/manual`;

      fetch(apiEndPoint, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
          console.log(`reached here means it is some error case`);
          console.log(`re-enable the button`);
          setSaveUrlsBtnDisabled(false);
          // TODO: error handling
        })
        .then(async (result) => {
          // render create schedule page
          setSchedulePageFlag(true);
        })
        .catch((error) => {
          setSaveUrlsBtnDisabled(false);
        });
    }
  };

  const showTags = (tagValue) => {
    return (
      tagValue &&
      tagValue.map((option, index) => (
        <div className={"chip-div-style"} index={index}>
          <Chip
            label={option}
            onDelete={() => onDeleteFunc(option)}
            variant="outlined"
            className={!!pattern.test(option) ? null : "error-url"}
            invalid={!!pattern.test(option)}
          />
        </div>
      ))
    );
  };

  const onChangeValue = (event, newValue) => {
    let filteredAndSortedUrls = sortListOfUrls(Object.values(newValue));
    setValue(filteredAndSortedUrls);
  };

  const onChangeInputValue = (event, newInputValue) => {
    let inputUrlList = newInputValue.replace(/['"]+/g, "").split(/[\s,]+/);

    const unique = new Set(inputUrlList);
    const backToArray = [...unique];
    let array = backToArray.filter((el) => !value.includes(el.trim()));

    if (inputUrlList.length > 1) {
      let updatedValues = value
        .concat(array)
        .map((x) => x.trim())
        .filter((x) => x);

      let filteredAndSortedUrls = sortListOfUrls(
        Object.values(Object.values(updatedValues))
      );
      setValue(filteredAndSortedUrls);
    } else {
      setInputValue(newInputValue);
      setError(noMsg);
    }
  };

  return schedulePageFlag ? (
    <ScheduleOnboardingPage
      accessToken={props.accessToken}
      tokenType={props.tokenType}
      expiry={props.expiry}
      uid={props.uid}
      client={props.client}
      websiteId={props.websiteId}
      service_level={props.service_level}
      service_level_id={props.service_level_id}
    ></ScheduleOnboardingPage>
  ) : (
    <div>
      <Header screenNumber={3}></Header>
      <div>
        <p className={"add-urls-header"}>{manualAddUrlsPage.ADD_URLS_TITLE}</p>
        <p className={"add-urls-header2"}>
          {manualAddUrlsPage.ADD_URLS_SUBTITLE}
        </p>
        <div className={"header-alignment"}>
          <p className={"copy-paste-header"}>
            {manualAddUrlsPage.COPY_AND_PASTER_URLS_LABEL}
          </p>
        </div>

        <div>
          <ToastContainer />
        </div>

        <div className={"div-alignment"}>
          <Autocomplete
            multiple
            freeSolo
            autoFocus
            id="tags-standard"
            options={[]}
            filterSelectedOptions={true}
            value={value}
            inputValue={inputValue}
            className={"autocomplete-style"}
            onChange={(event, newValue) => onChangeValue(event, newValue)}
            onInputChange={(event, newInputValue) =>
              onChangeInputValue(event, newInputValue)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                onKeyPress={(ev) => {
                  if (ev.key === manualAddUrlsPage.ENTER) {
                    ev.preventDefault();
                  }
                }}
                placeholder={noMsg}
                InputProps={{
                  style: { fontFamily: "Poppins, sans-serif" },
                  ...params.InputProps,
                  disableUnderline: true,
                  autoFocus: true,
                }}
                className={"text-field-style"}
              />
            )}
            renderTags={(tagValue, getTagProps) => {
              return showTags(value);
            }}
          />
        </div>

        <div className="button-alignment">
          <p className={"error-text"}>{errors}</p>
          <button
            className={
              value.length === 0 || invalidUrls.length > 0
                ? "disabled-save-new-urls-button"
                : "save-new-urls-button"
            }
            disabled={saveUrlsBtnDisabled}
            type={manualAddUrlsPage.SUBMIT}
            onClick={(e) => handleOnClick()}
          >
            {manualAddUrlsPage.SAVE_NEW_URLS_TEXT}
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ManualAddUrls;
