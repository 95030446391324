/** @format */

import "./index.css";
import React, { useState } from "react";
import { Skeleton } from "@material-ui/lab";

const Logo = () => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div>
      {loaded ? null : (
        <Skeleton
          variant="rectangular"
          animation="wave"
          className={"logo-notloaded"}
          width={269}
          height={71}
        />
      )}
      <img
        className={loaded ? "logo" : "display-none"}
        src="/images/logo-desktop.jpeg"
        alt="seoradar logo"
        onLoad={() => setLoaded(true)}
      ></img>
    </div>
  );
};

export default Logo;
