/** @format */

import React from "react";
import PropTypes from "prop-types";
import MaterialChip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";

const Chip = (props) => {
  const StyledChip = withStyles({
    deleteIcon: {
      color: props.invalid ? null : "red",
    },
  })(MaterialChip);

  return <StyledChip {...props} />;
};

Chip.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.oneOf(["regular, outlined"]),
};

export default Chip;
