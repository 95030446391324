/** @format */

import "./index.css";
import React, { useState } from "react";
import strings from "../../strings";
import ManualAddUrls from "../ManualAddUrls";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
import ScheduleOnboardingPage from "../ScheduleOnboardingPage";
import { Skeleton } from "@material-ui/lab";

// extract object for this page from main strings object
const {
  addUrlPage,
  httpHeaderKeys,
  seoradarServiceLevels,
  httpMethods,
  httpHeaderValues,
} = strings;

const UrlsToAdd = 100;

const AddUrlPage = (props) => {
  const [openAddUrlPage, setAddUrlpage] = useState(true);
  const [schedulePageFlag, setSchedulePageFlag] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [autoAddUrlsBtnDisabled, setAutoAddUrlsBtnDisabled] = useState(false);

  const handleManualAddUrlsClick = () => {
    setAddUrlpage(!openAddUrlPage);
  };

  const handleAutoAddUrlsClick = () => {
    var myHeaders = new Headers();
    myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
    myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
    myHeaders.append(httpHeaderKeys.CLIENT, props.client);
    myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
    myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
    myHeaders.append(httpHeaderKeys.UID, props.uid);

    var requestBody = JSON.stringify({ number_of_urls_to_add: UrlsToAdd });

    var requestOptions = {
      method: httpMethods.POST,
      headers: myHeaders,
      body: requestBody,
    };
    const { websiteId } = props;
    const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/websites/${websiteId}/add_urls/auto`;
    console.log(`disable the button to avoid any more API Calls`);
    setAutoAddUrlsBtnDisabled(true);
    fetch(apiEndPoint, requestOptions)
      .then((response) => {
        if (response.status === 202) {
          return response.text();
        }
        console.log(`some error case, re-enable the button`);
        setAutoAddUrlsBtnDisabled(false);
        // TODO: error handling
      })
      .then(async (result) => {
        // render create schedule page
        setSchedulePageFlag(true);
      })
      .catch((error) => {
        console.log(`Error msg: ${error.message}`);
        setAutoAddUrlsBtnDisabled(false);
      });
  };

  return schedulePageFlag ? (
    <ScheduleOnboardingPage
      accessToken={props.accessToken}
      tokenType={props.tokenType}
      expiry={props.expiry}
      uid={props.uid}
      client={props.client}
      websiteId={props.websiteId}
      service_level={props.service_level}
      service_level_id={props.service_level_id}
    ></ScheduleOnboardingPage>
  ) : openAddUrlPage ? (
    <div>
      <Header screenNumber={3}></Header>
      <div>
        <div className="add-url-title">
          <p>{addUrlPage.ADD_URL_TITLE}</p>
        </div>
        <div className="add-url-description-container">
          <p className="add-url-description-text">
            {addUrlPage.ADD_URL_DESCRIPTION}
          </p>
        </div>
        <div>
          <ToastContainer />
        </div>
        <div className="add-url-container">
          <div className="auto-add-url-container">
            <div className="auto-add-img-container">
              <div className="auto-add-left-region"></div>
              <div className="auto-add-middle-region">
                <picture>
                  <source
                    media="(min-width:550px)"
                    srcset="/images/auto-add-icon.svg"
                  ></source>
                  {loaded ? null : (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      className={"auto-add-url-img-not-loaded"}
                      width={155}
                      height={155}
                    />
                  )}
                  <img
                    alt="Auto Add URLs"
                    className={loaded ? "auto-add-url-img" : "display-none"}
                    src="/images/auto-add-mobile.png"
                    onLoad={() => setLoaded(true)}
                  ></img>
                </picture>
              </div>
              <div className="auto-add-right-region"></div>
            </div>
            <div className="auto-add-btn-container">
              <div className="auto-add-btn-left"></div>
              <div className="auto-add-btn-middle">
                <button
                  disabled={autoAddUrlsBtnDisabled}
                  onClick={handleAutoAddUrlsClick}
                  className="auto-add-url-button"
                >
                  {addUrlPage.AUTO_ADD_URLS_BTN_LABEL}
                </button>
              </div>
              <div className="auto-add-btn-right"></div>
            </div>
            <div className="auto-add-text-container">
              <div className="auto-add-text-left"></div>
              <div className="auto-add-text-middle">
                <p className="auto-add-help-text">
                  {addUrlPage.AUTO_ADD_URLS_HELP_TEXT}
                </p>
              </div>
              <div className="auto-add-text-right"></div>
            </div>
          </div>
          <div className="separation-div"></div>
          <div className="manual-add-url-container">
            <div className="manual-add-img-container">
              <div className="manual-add-left-region"></div>
              <div className="manual-add-middle-region">
                <picture>
                  <source
                    media="(min-width:550px)"
                    srcset="/images/manual-add-icon.svg"
                  ></source>
                  {loaded ? null : (
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      className={"auto-add-url-img-not-loaded"}
                      width={155}
                      height={155}
                    />
                  )}
                  <img
                    alt="Manual Add URLs"
                    className={loaded ? "auto-add-url-img" : "display-none"}
                    src="/images/manual-add-mobile.png"
                    onLoad={() => setLoaded(true)}
                  ></img>
                </picture>
              </div>
              <div className="manual-add-right-region"></div>
            </div>
            <div className="manual-add-btn-container">
              <div className="manual-add-btn-left"></div>
              <div className="manual-add-btn-middle">
                <button
                  onClick={handleManualAddUrlsClick}
                  className="manual-add-url-button"
                >
                  {addUrlPage.MANUAL_ADD_URLS_BTN_LABEL}
                </button>
              </div>
              <div className="manual-add-btn-right"></div>
            </div>
            <div className="manual-add-text-container">
              <div className="manual-add-text-left"></div>
              <div className="manual-add-text-middle">
                <p className="manual-add-help-text">
                  {addUrlPage.MANUAL_ADD_URLS_HELP_TEXT}
                </p>
              </div>
              <div className="manual-add-text-right"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  ) : (
    <ManualAddUrls
      accessToken={props.accessToken}
      tokenType={props.tokenType}
      expiry={props.expiry}
      uid={props.uid}
      client={props.client}
      websiteId={props.websiteId}
      domainUrl={props.domainUrl}
      allowSubdomainUrls={props.allowSubdomainUrls}
      service_level={props.service_level}
      service_level_id={props.service_level_id}
    ></ManualAddUrls>
  );
};

export default AddUrlPage;
