/** @format */

import "./index.css";
import React, { useState } from "react";
import FreeTrialSection from "./FreeTrialSection";
import EnterDomainPage from "./../EnterDomainPage/index";
import SignInPage from "./../SignInPage/index";
import strings from "../../strings";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";

import { SignInWithGoogle } from "../common/OAuth"

const {
  prodUrl,
  stagingUrl,
  minPwdLengthErrorMsg,
  minPwdLength,
  noMsg,
  invalidEmailErrorMsg,
  noNameEnteredErrorMsg,
  minNameLength,
  validEmailRegEx,
  signUpPage,
  httpHeaderKeys,
  httpHeaderValues,
  seoradarServiceLevels,
  httpMethods,
  staticPageLinks,
  emailInUseErrorMsg,
} = strings;

const SignupPage = (props) => {
  const [error, setError] = useState(noMsg);
  const [nameError, setNameError] = useState(noMsg);
  const [emailError, setEmailError] = useState(noMsg);
  const [passwordError, setPasswordError] = useState(noMsg);

  const [accessToken, setAccessToken] = useState(noMsg);
  const [client, setClient] = useState(noMsg);
  const [expiry, setExpiry] = useState(noMsg);
  const [tokenType, setTokenType] = useState(noMsg);
  const [uid, setUId] = useState(noMsg);

  const [email, setEmail] = useState(noMsg);
  const [name, setName] = useState(noMsg);
  const [password, setPassword] = useState(noMsg);
  const [checked, setChecked] = useState(false);
  const [allowSubdomainUrls, setAllowSubdomainUrls] = useState(false);
  const [enterDomain, setEnterDOmain] = useState(false);
  const [signIn, setSignIn] = useState(false);

  const [submitBtnDisabled, SetSubmitBtnDisabled] = useState(false);

  const clearErrors = () => {
    setNameError(noMsg);
    setEmailError(noMsg);
    setPasswordError(noMsg);
    setError(noMsg);
  };

  const passwordHandler = (e) => {
    clearErrors();
    let elm = e.target;
    if (e.target.value.length < minPwdLength) {
      elm.style.border = "1px solid #d0021b";
      setPasswordError(minPwdLengthErrorMsg);
    } else {
      elm.style.border = "";
      setPasswordError(noMsg);
    }
    setPassword(e.target.value);
  };

  const emailHandler = (e) => {
    clearErrors();
    let elm = e.target;
    let email = e.target.value;
    const emailRegex = validEmailRegEx;
    if (!emailRegex.test(String(email).toLowerCase())) {
      elm.style.border = "1px solid #d0021b";
      setEmailError(invalidEmailErrorMsg);
    } else {
      elm.style.border = "";
      setEmailError(noMsg);
    }
    setEmail(email);
  };

  const nameHandler = (e) => {
    clearErrors();
    let elm = e.target;
    if (e.target.value.length < minNameLength) {
      elm.style.border = "1px solid #d0021b";
      setNameError(noNameEnteredErrorMsg);
    } else {
      elm.style.border = "";
      setNameError(noMsg);
    }
    setName(e.target.value);
  };

  const setCheckedBox = () => {
    clearErrors();
    setChecked(!checked);

    if (name === noMsg) {
      setNameError(noNameEnteredErrorMsg);
    }
    if (password === noMsg) {
      setPasswordError(minPwdLengthErrorMsg);
    }
    if (email === noMsg) {
      setEmailError(invalidEmailErrorMsg);
    }
    setError(noMsg);
  };

  const createAccount = async (e) => {
    e.preventDefault();
    const emailRegex = validEmailRegEx;

    if (!checked) {
      setError(signUpPage.TERMS_NOT_ACCEPTED_ERR_MSG);
    }
    if (name.length < minNameLength) {
      let elms = document.getElementsByName("name");
      for (let i = 0; i < elms.length; i++) {
        elms[i].style.border = "1px solid #d0021b";
      }
      setNameError(noNameEnteredErrorMsg);
    }
    if (!emailRegex.test(String(email).toLowerCase())) {
      let elms = document.getElementsByName("email");
      for (let i = 0; i < elms.length; i++) {
        elms[i].style.border = "1px solid #d0021b";
      }
      setEmailError(invalidEmailErrorMsg);
    }
    if (password.length < minPwdLength) {
      let elms = document.getElementsByName("password");
      for (let i = 0; i < elms.length; i++) {
        elms[i].style.border = "1px solid #d0021b";
      }
      setPasswordError(minPwdLengthErrorMsg);
    }
    if (checked && !error && !nameError && !emailError && !passwordError) {
      SetSubmitBtnDisabled(true);
      var myHeaders = new Headers();
      myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);

      var requestBody = JSON.stringify({
        name: name,
        email: email,
        password: password,
        service_level: props.service_level,
        service_level_id: props.service_level_id,
      });

      var requestOptions = {
        method: httpMethods.POST,
        headers: myHeaders,
        body: requestBody,
      };
      const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/auth`;
      fetch(apiEndPoint, requestOptions)
        .then((response) => {
          let headers = response.headers;
          myHeaders.append(
            httpHeaderKeys.CONTENT_TYPE,
            httpHeaderValues.JSON_TYPE
          );
          setAccessToken(headers.get(httpHeaderKeys.ACCESS_TOKEN));
          setTokenType(headers.get(httpHeaderKeys.TOKEN_TYPE));
          setClient(headers.get(httpHeaderKeys.CLIENT));
          setExpiry(headers.get(httpHeaderKeys.EXPIRY));
          setUId(headers.get(httpHeaderKeys.UID));

          return response.text();
        })
        .then(async (result) => {
          const resultJson = JSON.parse(result);
          let message = resultJson.message;
          if (message === signUpPage.ACCOUNT_CREATED) {
            setAllowSubdomainUrls(resultJson.data.allow_subdomain_urls);
            if (process.env.REACT_APP_API_URI === prodUrl) {
              let script_tag = document.createElement("script");
              script_tag.type = "text/javascript";
              let code =
                "window['_fs_debug'] = false;window['_fs_host'] = 'fullstory.com';window['_fs_script'] = 'edge.fullstory.com/s/fs.js';window['_fs_org'] = '11FHH4';window['_fs_namespace'] = 'FS';(function(m,n,e,t,l,o,g,y){if (e in m) {if(m.console && m.console.log) { m.console.log('FullStory namespace conflict. Please set window[\"_fs_namespace\"].');} return;}g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];o=n.createElement(t);o.async=1;o.crossOrigin='anonymous';o.src='https://'+_fs_script;y=n.getElementsByTagName(t)[0];y.parentNode.insertBefore(o,y);g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};g.anonymize=function(){g.identify(!!0)};g.shutdown=function(){g(\"rec\",!1)};g.restart=function(){g(\"rec\",!0)};g.log = function(a,b){g(\"log\",[a,b])};g.consent=function(a){g(\"consent\",!arguments.length||a)};g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};g.clearUserCookie=function(){};g.setVars=function(n, p){g('setVars',[n,p]);};g._w={};y='XMLHttpRequest';g._w[y]=m[y];y='fetch';g._w[y]=m[y];if(m[y])m[y]=function(){return g._w[y].apply(this,arguments)};g._v=\"1.3.0\";})(window,document,window['_fs_namespace'],'script','user');";
              script_tag.appendChild(document.createTextNode(code));
              document.getElementsByTagName("head")[0].appendChild(script_tag);
            }
            setEnterDOmain(true);
          } else if (message === emailInUseErrorMsg) {
            SetSubmitBtnDisabled(false);
            setError(emailInUseErrorMsg);
          } else {
            SetSubmitBtnDisabled(false);
            setError(message);
          }
        })
        .catch((error) => {
          console.log(`Error msg: ${error.message}`);
          SetSubmitBtnDisabled(false);
        });
    }
  };

  const showSignUpView = () => {
    return enterDomain === false && signIn === false;
  };

  const goToSignInPage = () => {
    setSignIn(true);
  };

  const googleCallback = (res) => {
    console.log(res);
    if (!res.success) {
      console.log(res)
      return
    }

    let data = res.data;

    if (!data.new_user) {
      return window.location.href = "/";
    }

    setAccessToken(data.token['access-token']);
    setTokenType(data.token['token-type']);
    setClient(data.token.client);
    setExpiry(data.token.expiry);
    setUId(data.token.uid);

    setEnterDOmain(true);
  }

  return showSignUpView() === true ? (
    <div>
      <Header screenNumber={1}></Header>
      <div className="main-container">
        <div className="top-margin"></div>

        <div className="title-section">
          <p className="free-trial-title">
            {signUpPage.START_FREE_TRIAL_TITLE}
          </p>
          <p className="create-account-text">
            {signUpPage.CREATE_ACCOUNT_TITLE}
          </p>
        </div>

        <div className="signup-mobile-view" id="mobile-view">
          <div className="signup-div">
            <div className="row row-style-signup">
              <div className="col">
                <div className="row row-style-signup">
                  <div className="col-xs-0 col-sm-2 col-md-4 col-lg-4"></div>
                  <div className="col-xs-12 col-sm-5 col-md-4 col-lg-5">
                    <form
                      id="mobileform"
                      onSubmit={createAccount}
                      className="form-signup"
                    >
                      <div className="input-field-container">
                        <input
                          className={
                            name
                              ? "input-value-text padded-input"
                              : "placeholder-text padded-input"
                          }
                          type="text"
                          name="name"
                          placeholder="Name"
                          onClick={nameHandler}
                          onChange={nameHandler}
                          value={name}
                        ></input>
                        <p className={"signup-page-error-text"}>{nameError}</p>
                      </div>
                      <div className="input-field-container">
                        <input
                          className={
                            email
                              ? "input-value-text padded-input"
                              : "placeholder-text padded-input"
                          }
                          type="text"
                          name="email"
                          placeholder="Email"
                          onClick={emailHandler}
                          onChange={emailHandler}
                          value={email}
                        ></input>
                        <p className={"signup-page-error-text"}>{emailError}</p>
                      </div>
                      <div className="input-field-container">
                        <input
                          className={
                            password
                              ? "input-value-text padded-input"
                              : "placeholder-text padded-input"
                          }
                          type="password"
                          name="password"
                          placeholder="Password"
                          onClick={passwordHandler}
                          onChange={passwordHandler}
                          value={password}
                        ></input>
                        <p className={"signup-page-error-text"}>
                          {passwordError}
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 agree-container">
                  <div>
                    <div className="agree">
                      <input
                        id="agree-checkbox-bigger-device"
                        className="checkbox"
                        onChange={() => setCheckedBox()}
                        type="checkbox"
                        value={checked}
                      />

                      <p className="agree-text">
                        {signUpPage.AGREE_TO_TERMS_PART_1}{" "}
                        <a
                          id="terms_of_svc"
                          target="_blank"
                          href={staticPageLinks.TERMS_OF_SVC}
                          rel="noreferrer"
                        >
                          {signUpPage.AGREE_TO_TERMS_PART_2}
                        </a>{" "}
                        and{" "}
                        <a
                          id="privacy_policy"
                          target="_blank"
                          href={staticPageLinks.PRIVACY_POLICY}
                          rel="noreferrer"
                        >
                          {signUpPage.AGREE_TO_TERMS_PART_3}
                        </a>
                      </p>
                    </div>
                    <p className={"checkbox-error-text"}>{error}</p>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 button-alignment">
                <button
                  disabled={submitBtnDisabled}
                  form="mobileform"
                  className="create-account-button"
                  type="submit"
                >
                  {signUpPage.START_FREE_TRIAL_BTN_LABEL}
                </button>
              </div>

              <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 fts-container">
                <FreeTrialSection></FreeTrialSection>
              </div>
            </div>
          </div>
        </div>

        <div className="non-mobile-device">
          <div className="not-for-tab" id="desktop-view">
            <form
              id="nonMobform"
              onSubmit={createAccount}
              className="form-signup"
            >
              <div className="input-field-container">
                <input
                  className={
                    name
                      ? "input-value-text padded-input"
                      : "placeholder-text padded-input"
                  }
                  type="text"
                  name="name"
                  placeholder="Name"
                  onClick={nameHandler}
                  onChange={nameHandler}
                  value={name}
                ></input>
                <p className={"signup-page-error-text"}>{nameError}</p>
              </div>
              <div className="input-field-container">
                <input
                  className={
                    email
                      ? "input-value-text padded-input"
                      : "placeholder-text padded-input"
                  }
                  type="text"
                  name="email"
                  placeholder="Email"
                  onClick={emailHandler}
                  onChange={emailHandler}
                  value={email}
                ></input>
                <p className={"signup-page-error-text"}>{emailError}</p>
              </div>
              <div className="input-field-container">
                <input
                  className={
                    password
                      ? "input-value-text padded-input"
                      : "placeholder-text padded-input"
                  }
                  type="password"
                  name="password"
                  placeholder="Password"
                  onClick={passwordHandler}
                  onChange={passwordHandler}
                  value={password}
                ></input>
                <p className={"signup-page-error-text"}>{passwordError}</p>
              </div>
            </form>
            <FreeTrialSection></FreeTrialSection>
          </div>
          <div className="for-tab-only" id="tab-view">
            <form
              id="nonMobform"
              onSubmit={createAccount}
              className="form-signup"
            >
              <div className="input-field-container">
                <input
                  className={
                    name
                      ? "input-value-text padded-input"
                      : "placeholder-text padded-input"
                  }
                  type="text"
                  name="name"
                  placeholder="Name"
                  onClick={nameHandler}
                  onChange={nameHandler}
                  value={name}
                ></input>
                <p className={"signup-page-error-text"}>{nameError}</p>
              </div>
              <div className="input-field-container">
                <input
                  className={
                    email
                      ? "input-value-text padded-input"
                      : "placeholder-text padded-input"
                  }
                  type="text"
                  name="email"
                  placeholder="Email"
                  onClick={emailHandler}
                  onChange={emailHandler}
                  value={email}
                ></input>
                <p className={"signup-page-error-text"}>{emailError}</p>
              </div>
              <div className="input-field-container">
                <input
                  className={
                    password
                      ? "input-value-text padded-input"
                      : "placeholder-text padded-input"
                  }
                  type="password"
                  name="password"
                  placeholder="Password"
                  onClick={passwordHandler}
                  onChange={passwordHandler}
                  value={password}
                ></input>
                <p className={"signup-page-error-text"}>{passwordError}</p>
              </div>
            </form>
            <FreeTrialSection></FreeTrialSection>
          </div>
          <div className="agree-container">
            <div className="agree">
              <input
                id="agree-checkbox-bigger-device"
                className="checkbox"
                onChange={() => setCheckedBox()}
                type="checkbox"
                value={checked}
              />
              <p className="agree-text">
                {signUpPage.AGREE_TO_TERMS_PART_1}{" "}
                <a
                  id="terms_of_svc"
                  target="_blank"
                  href="https://www.seoradar.com/tos/"
                  rel="noreferrer"
                >
                  {signUpPage.AGREE_TO_TERMS_PART_2}
                </a>{" "}
                and{" "}
                <a
                  id="privacy_policy"
                  target="_blank"
                  href="https://www.seoradar.com/privacy-policy/"
                  rel="noreferrer"
                >
                  {signUpPage.AGREE_TO_TERMS_PART_3}
                </a>
              </p>
            </div>
          </div>
          <p className={"checkbox-error-text"}>{error}</p>
          <div className="button-alignment">
            <button
              disabled={submitBtnDisabled}
              form="nonMobform"
              className="create-account-button"
              type="submit"
            >
              {signUpPage.START_FREE_TRIAL_BTN_LABEL}
            </button>
          </div>
          <div className="margin">
            <div className="row row-style-signup"></div>
            <div className="row row-style-signup">
              <div className="col-xs-12">
                <p className="signup-form-no-credit-card">
                  {signUpPage.submitSection.NO_CC_REQUIRED}
                </p>
              </div>
            </div>
            <div className="row row-style-signup">
              <div className="account-exists-section">
                <span className="account-exists-text">
                  {signUpPage.submitSection.ALREADY_HAVE_ACCOUNT}
                </span>
                <button
                  className="signup-form-signin-link"
                  onClick={goToSignInPage}
                >
                  {signUpPage.submitSection.SIGN_IN_TXT}
                </button>
              </div>
            </div>
          </div>{" "}
          <span className="horizontal-devider">or</span>
          <div className="oauth-providers">
            <SignInWithGoogle
              onSuccess={googleCallback}
              isSignIn={false}
              serviceLevelId={props.service_level_id}
            ></SignInWithGoogle>
          </div>
          <div className="oauth-providers">
            <div className="oauth-button-text enterprise-sign">
              <a href="/auth/auth0">Enterpise Sign-in</a>
            </div>
          </div>
          <div className="bottom-margin"></div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  ) : enterDomain === true ? (
    <EnterDomainPage
      accessToken={accessToken}
      tokenType={tokenType}
      expiry={expiry}
      uid={uid}
      client={client}
      service_level={props.service_level}
      service_level_id={props.service_level_id}
      allowSubdomainUrls={allowSubdomainUrls}
    ></EnterDomainPage>
  ) : (
    <SignInPage></SignInPage>
  );
};

export default SignupPage;
