/** @format */

import "./index.css";
import strings from "../../../strings";
const { signUpPage } = strings;
const FreeTrialSection = () => {
  return (
    <div className="signup-form-free-trial-container">
      <p className="signup-form-free-trial-title">
        {signUpPage.freeTrialInfo.TITLE}
      </p>
      <div className="signup-form-free-trial-statement signup-form-free-trial-first-statement">
        <img
          className="signup-form-first-tick"
          src="/images/purple-tick.svg"
          alt="tick"
        ></img>
        <p className="signup-form-free-trial-line">
          {signUpPage.freeTrialInfo.FIRST_POINT}
        </p>
      </div>
      <div className="signup-form-free-trial-statement signup-form-free-trial-second-statement">
        <img
          className="signup-form-second-tick"
          src="/images/purple-tick.svg"
          alt="tick"
        ></img>
        <p className="signup-form-free-trial-line">
          {signUpPage.freeTrialInfo.SECOND_POINT}
        </p>
      </div>
      <div className="signup-form-free-trial-statement signup-form-free-trial-third-statement">
        <img
          className="signup-form-second-tick"
          src="/images/purple-tick.svg"
          alt="tick"
        ></img>
        <p className="signup-form-free-trial-line signup-form-free-trial-line-3">
          {signUpPage.freeTrialInfo.THIRD_POINT}
        </p>
      </div>
      <div className="signup-form-free-trial-statement signup-form-free-trial-fourth-statement">
        <img
          className="signup-form-fourth-tick"
          src="/images/purple-tick.svg"
          alt="tick"
        ></img>
        <p className="signup-form-free-trial-line signup-form-free-trial-line-4">
          {signUpPage.freeTrialInfo.FOURTH_POINT}
        </p>
      </div>
    </div>
  );
};

export default FreeTrialSection;
