import SignInPage from "../SignInPage/index";
import "./index.css";
import { useState } from "react";
import strings from "../../strings";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
const {
  validEmailRegEx,
  invalidEmailErrorMsg,
  cssElmIDs,
  resetPasswordPage,
  emailFieldPlaceHolder,
  cancelBtnLabel,
  noMsg,
  httpHeaderKeys,
  httpHeaderValues,
  httpMethods,
} = strings;

const pwdResetLinkSent =
  "You will receive an email with instructions about how to reset your password in a few minutes";

function ResetPasswordPage() {
  // State-handling
  const [emailError, setEmailError] = useState(noMsg);
  const [email, setEmail] = useState(noMsg);
  const [goToLogInPageFlag, setGoToLogInPageFlag] = useState(false);
  const [goBackToLogInPageFlag, setGoBackToLogInPageFlag] = useState(false);

  const goBackToLogInPage = () => {
    setGoBackToLogInPageFlag(true);
  };

  const emailHandler = (e) => {
    let elm = e.target;
    let email = e.target.value;
    const emailRegex = validEmailRegEx;
    if (!emailRegex.test(String(email).toLowerCase())) {
      setEmailError(invalidEmailErrorMsg);
      elm.classList.add("input-with-error");
    } else {
      setEmailError(noMsg);
      elm.classList.remove("input-with-error");
    }
    setEmail(email);
  };

  const resetPassword = (e) => {
    e.preventDefault();
    let elm = document.getElementById(cssElmIDs.EMAIL_FIELD);
    if (email.length < 1) {
      setEmailError(invalidEmailErrorMsg);
      elm.classList.add("input-with-error");
      return;
    }
    // make API Call with entered email as Input
    var myHeaders = new Headers();
    myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
    var requestBody = JSON.stringify({ email: email });
    var requestOptions = {
      method: httpMethods.POST,
      headers: myHeaders,
      body: requestBody,
    };
    const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/auth/password`;
    fetch(apiEndPoint, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setGoToLogInPageFlag(true);
        }
        if (response.status === 400) {
          return response.json();
        }
      })
      .then((result) => {
        let noSuchAccountMsg = `No user found with email id:${email}`;
        if (result.message === noSuchAccountMsg) {
          setEmailError("There is no account associated with entered Email.");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return goBackToLogInPageFlag === true ? (
    <SignInPage showNotification={false}></SignInPage>
  ) : goToLogInPageFlag === true ? (
    <SignInPage showNotification={true} msg={pwdResetLinkSent}></SignInPage>
  ) : (
    <div>
      <Header></Header>
      <div>
        <div className="reset-password-title-container">
          <p className="reset-password-title-text">{resetPasswordPage.TITLE}</p>
        </div>
        <div className="reset-password-description-container">
          <p className="reset-password-description-text">
            {resetPasswordPage.DESCRIPTION}
          </p>
        </div>
        <div className="form-container">
          <form
            id="reset-password-form"
            className="form"
            onSubmit={resetPassword}
          >
            <div>
              <input
                className="email-field-rp"
                type="text"
                placeholder={emailFieldPlaceHolder}
                onFocus={emailHandler}
                onChange={emailHandler}
                value={email}
                id={cssElmIDs.EMAIL_FIELD}
              ></input>
              <p className={"error-text"}>{emailError}</p>
            </div>
          </form>
        </div>
        <div className="button-container-rp">
          <button onClick={goBackToLogInPage} className="cancel-button">
            {cancelBtnLabel}
          </button>
          <button
            form="reset-password-form"
            className="reset-password-button"
            type="submit"
          >
            {resetPasswordPage.RESET_PWD_BTN_LABEL}
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default ResetPasswordPage;
