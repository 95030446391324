/** @format */

import { useState, useEffect } from "react";
import strings from "../../strings";
import EnterDomainPage from "./../EnterDomainPage/index";
import ResetPasswordPage from "../ResetPasswordPage/index";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
import SignUpPage from "../SignupPage";
import "./index.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SignInWithGoogle, SignInWithAuth0 } from "../common/OAuth"

const { signInPage } = strings;

const linkToPricingTable = "https://www.seoradar.com/pricing/";

const {
  noMsg,
  minPwdLengthErrorMsg,
  invalidEmailErrorMsg,
  notRegisteredEmailErrorMsg,
  validEmailRegEx,
  noPwdEnteredErrorMsg,
  minPwdLength,
  httpHeaderKeys,
  httpHeaderValues,
  httpMethods,
} = strings;

function SignInPage(props) {
  // State-handling
  const [emailError, setEmailError] = useState(noMsg);
  const [passwordError, setPasswordError] = useState(noMsg);
  const [email, setEmail] = useState(noMsg);
  const [password, setPassword] = useState(noMsg);
  const [signup, setSignUp] = useState(false);
  const [incorrectPwd, setIncorrectPwd] = useState(false);
  const forgotPwdStr = " Forgot Password?";
  const [goToResetPasswordPage, setGoToResetPasswordPage] = useState(false);
  const [goToOnboarding, setGoToOnboarding] = useState(false);

  const [accessToken, setAccessToken] = useState(noMsg);
  const [client, setClient] = useState(noMsg);
  const [expiry, setExpiry] = useState(noMsg);
  const [tokenType, setTokenType] = useState(noMsg);
  const [uid, setUId] = useState(noMsg);

  useEffect(() => {
    if (
      props.showNotification !== undefined &&
      props.showNotification === true
    ) {
      // Assuming if showNotification flag is present and true, then `msg` will also be set
      toast(props.msg);
    }
  }, [props.showNotification]);

  const resetPasswordPage = () => {
    setGoToResetPasswordPage(true);
  };

  const passwordHandler = (e) => {
    if (e.target.value.length < minPwdLength) {
      setPasswordError(minPwdLengthErrorMsg);
    } else {
      setPasswordError(noMsg);
    }
    setPassword(e.target.value);
  };

  const emailHandler = (e) => {
    let email = e.target.value;
    const emailRegex = validEmailRegEx;
    if (!emailRegex.test(String(email).toLowerCase())) {
      setEmailError(invalidEmailErrorMsg);
    } else {
      setEmailError(noMsg);
    }
    setEmail(email);
  };

  const signIn = (e) => {
    e.preventDefault();
    const emailRegex = validEmailRegEx;
    let someError = false;
    if (!emailRegex.test(String(email).toLowerCase())) {
      someError = true;
      setEmailError(notRegisteredEmailErrorMsg);
    }
    if (password.length < 1) {
      someError = true;
      setPasswordError(noPwdEnteredErrorMsg);
    }
    if (someError === false) {
      // proceed with API call only if no UI Error
      var myHeaders = new Headers();
      myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
      var requestBody = JSON.stringify({ email: email, password: password });
      var requestOptions = {
        method: httpMethods.POST,
        headers: myHeaders,
        body: requestBody,
      };

      const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/sessions`;
      fetch(apiEndPoint, requestOptions)
        .then((response) => {
          if (response.status === 401) {
            return response.json();
          }
          if (response.status === 200) {
            window.location.href = process.env.REACT_APP_API_URI;
          }
        })
        .then((data) => {
          if (data.message === "Invalid Password") {
            setIncorrectPwd(true);
          }
          if (data.message === "User with this email not found") {
            setEmailError(
              "Please enter your registered email address or sign up for a new account."
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const showSignInView = () => {
    return signup === false;
  };

  const goToSignUpPage = () => {
    setSignUp(true);
  };

  const goToPricingTable = () => {
    window.location.href = linkToPricingTable;
  };

  // const login = useGoogleLogin({
  //   onSuccess: codeResponse => {
  //     console.log(codeResponse)
  //     codeResponse.redirect_uri = "https://customer.seoradar.dev"
  //     axios.get("/auth/google_oauth2/callback", //codeResponse
  //       {
  //         params: codeResponse
  //       }
  //     ).then(res => {
  //       console.log(res)
  //     })
  //   },
  //   ux_mode: "popup",
  //   flow: 'auth-code',
  //   // redirect_uri: "https://customer.seoradar.dev"
  // });

  const googleCallback = (res) => {
    console.log(res);
    if (!res.success) {
      console.log(res)
      return
    }

    let data = res.data;

    if (!data.new_user) {
      return window.location.href = "/";
    }

    setAccessToken(data.token['access-token']);
    setTokenType(data.token['token-type']);
    setClient(data.token.client);
    setExpiry(data.token.expiry);
    setUId(data.token.uid);

    setGoToOnboarding(true);
  }

  if (goToOnboarding) {
    return (
      <EnterDomainPage
        accessToken={accessToken}
        tokenType={tokenType}
        expiry={expiry}
        uid={uid}
        client={client}
        setAllowSubdomainUrls={false}
        service_level={strings.seoradarServiceLevels.GOLD}
        service_level_id={strings.seoradarServiceLevels.GOLD_ID}
      >
      </EnterDomainPage>
    )
  }

  return goToResetPasswordPage === true ? (
    <ResetPasswordPage></ResetPasswordPage>
  ) : showSignInView() === true ? (
    <div>
      <Header></Header>
      <div>
        <div className="sign-in-title">
          <p>{signInPage.TITLE}</p>
        </div>
        <div className="sign-in-description">
          <p>{signInPage.DESCRIPTION}</p>
        </div>
        <div>
          <ToastContainer />
        </div>
        <div>
          <form id="sign-in-form" className="form" onSubmit={signIn}>
            <div className="email-field-container">
              <input
                className="email-field"
                type="text"
                placeholder="Email"
                onChange={emailHandler}
                value={email}
              ></input>
              <p className={"error-text-si"}>{emailError}</p>
            </div>
            <div>
              <input
                className="password-field"
                type="password"
                name="password"
                placeholder="Password"
                onChange={passwordHandler}
                value={password}
              ></input>

              {incorrectPwd === true && (
                <p>
                  <span className={"error-text-si left-error-text"}>
                    Incorrect password.
                  </span>
                  <span className={"error-text-si right-error-text"}>
                    <button
                      type="button"
                      onClick={resetPasswordPage}
                      className={"forgot-password-text"}
                    >
                      {forgotPwdStr}
                    </button>
                  </span>
                </p>
              )}
              {incorrectPwd === false && (
                <p className={"error-text-si"}>{passwordError}</p>
              )}
            </div>
          </form>
        </div>
        <div className="button-container-si">
          <button form="sign-in-form" className="sign-in-button" type="submit">
            {signInPage.SIGN_IN_BTN_LABEL}
          </button>
        </div>
        <div className="new-to-seoradar">
          <p className="new-to-seoradar-text">
            {signInPage.NEW_TO_SEORADAR}{" "}
            <button className="new-to-seoradar-link" onClick={goToPricingTable}>
              {signInPage.GET_STARTED}
            </button>
          </p>
        </div>
        <span className="horizontal-devider">or</span>
        <div className="oauth-providers">
          <SignInWithGoogle
            onSuccess={googleCallback}
            isSignIn={true}
            serviceLevelId={strings.seoradarServiceLevels.GOLD_ID}
          ></SignInWithGoogle>
        </div>
        <div className="oauth-providers">
          <div className="oauth-button-text enterprise-sign">
            <a href="/auth/auth0">Enterpise Sign-in</a>
          </div>
        </div>
      </div>
      {/* <Footer></Footer> */}
    </div>
  ) : (
    <SignUpPage></SignUpPage>
  );
}

export default SignInPage;
