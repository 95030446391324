import { useGoogleLogin } from '@react-oauth/google';
import axios, { isCancel, AxiosError } from 'axios';
import strings from "../../../strings";

import GoogleIcon from "./google.svg"

export function SignInWithGoogle(props) {
    const login = useGoogleLogin({
        onSuccess: codeResponse => {
            console.log(codeResponse)
            codeResponse.redirect_uri = process.env.REACT_APP_API_URI.slice(0, -1);
            codeResponse.service_level_id = props.serviceLevelId;
            axios.get("/auth/google_oauth2/callback", {
                params: codeResponse
            }).then(res => {
                props.onSuccess(res.data);
            })
        },
        ux_mode: "popup",
        flow: 'auth-code',
        include_granted_scopes: false
        // redirect_uri: "https://customer.seoradar.dev"
    });

    return (
        <button onClick={() => login()} className="oauth-button">
            <img src={GoogleIcon} className="oauth-button-icon" />
            <div className="oauth-button-text">
                {props.isSignIn ? 'Sign in' : 'Sign up'} with Google
            </div>
        </button>
    )
}
