import { useState } from "react";
import SignInPage from "../SignInPage/index";
import "./index.css";
import strings from "../../strings";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
const { changePasswordPage } = strings;

const {
  noMsg,
  minPwdLength,
  minPwdLengthErrorMsg,
  httpHeaderKeys,
  httpHeaderValues,
  httpMethods,
} = strings;

const pwdUpdated =
  "Your password has been successfully changed. Please log in with updated password.";

function ChangePasswordPage({ match, location }) {
  let queryParamStr = location.search;
  let resetPasswordToken = null;
  if (queryParamStr !== "") {
    let kv = queryParamStr.split("?")[1];
    resetPasswordToken = kv.split("=")[1];
  }

  // State-handling
  const [passwordError, setPasswordError] = useState(noMsg);
  const [verifyPasswordError, setVerifyPasswordError] = useState(noMsg);
  const [password, setPassword] = useState(noMsg);
  const [verifyPassword, setVerifyPassword] = useState(noMsg);
  const [goToLogInPage, setGoToLogInPage] = useState(false);
  const [errorFromBackend, setErrorFromBackend] = useState("");

  const passwordHandler = (e) => {
    if (e.target.value.length < minPwdLength) {
      setPasswordError(minPwdLengthErrorMsg);
    } else {
      setPasswordError(noMsg);
    }
    setPassword(e.target.value);
  };

  const verifyPasswordHandler = (e) => {
    if (e.target.value.length < minPwdLength) {
      setVerifyPasswordError(minPwdLengthErrorMsg);
    } else {
      setVerifyPasswordError(noMsg);
    }
    setVerifyPassword(e.target.value);
  };

  const changePassword = (e) => {
    e.preventDefault();
    if (password === noMsg || verifyPassword === noMsg) {
      if (password === noMsg) {
        setPasswordError("Please enter a new password");
      }
      if (verifyPassword === noMsg) {
        setVerifyPasswordError("Please confirm your password");
      }
      return;
    }
    if (password !== verifyPassword) {
      setVerifyPasswordError("Verify password does not match password.");
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
    var requestBody = JSON.stringify({
      password: password,
      password_confirmation: verifyPassword,
      reset_password_token: resetPasswordToken,
    });
    var requestOptions = {
      method: httpMethods.PATCH,
      headers: myHeaders,
      body: requestBody,
    };
    const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/auth/password`;
    fetch(apiEndPoint, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          setGoToLogInPage(true);
        }
        if (response.status === 401) {
          return response.json();
        }
      })
      .then((result) => {
        setErrorFromBackend("The reset password token is incorrect");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return goToLogInPage === true ? (
    <SignInPage showNotification={true} msg={pwdUpdated}></SignInPage>
  ) : (
    <div>
      <Header></Header>
      <div>
        <div className="change-password-title">
          <p>{changePasswordPage.TITLE}</p>
        </div>
        <div className="change-password-description">
          <p>{changePasswordPage.DESCRIPTION}</p>
        </div>
        <div>
          <form
            id="change-password-form"
            className="form"
            onSubmit={changePassword}
          >
            <div className="password-field-container">
              <input
                className="password-field-cp"
                type="password"
                placeholder="Password"
                onChange={passwordHandler}
                value={password}
              ></input>
              <p className={"error-text"}>{passwordError}</p>
            </div>
            <div className="verify-password-field-container">
              <input
                className="verify-password-field-cp"
                type="password"
                name="password"
                placeholder="Verify Password"
                onChange={verifyPasswordHandler}
                value={verifyPassword}
              ></input>
              <p className={"error-text"}>{verifyPasswordError}</p>
            </div>
          </form>
        </div>
        <p className={"error-text backend-error"}>{errorFromBackend}</p>
        <div className="button-container">
          <button
            form="change-password-form"
            className="change-password-button"
            type="submit"
          >
            {changePasswordPage.BTN_LABEL}
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default ChangePasswordPage;
