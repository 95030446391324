/** @format */

const strings = {
  prodUrl: "https://app.seoradar.com/",
  stagingUrl: "https://customers.staging.seoradar.net/",
  localUrl: "https://customer.seoradar.local/",
  addUrlPage: {
    ADD_URL_TITLE: "Add URLs to Monitor",
    ADD_URL_DESCRIPTION: "Get SEO-focused change alerts for these URLs",
    AUTO_ADD_URLS_BTN_LABEL: "AUTO ADD URLs",
    MANUAL_ADD_URLS_BTN_LABEL: "MANUAL ADD URLs",
    AUTO_ADD_URLS_HELP_TEXT:
      "The top 100 pages of your site will be automatically added.",
    MANUAL_ADD_URLS_HELP_TEXT: "Copy/paste a specific set of URLs to monitor.",
    ENTER: "Enter",
    SUBMIT: "submit",
  },
  enterDomainPage: {
    VALID_DOMAIN_MSG: "enter valid domain",
    DOMAIN_CREATED_OK: "Domain successfully created",
    TITLE: "Enter a Domain To Monitor",
    TOOLTIP:
      "This is the top level website for seo radar to monitor.  We will validate the domain and will fetch the robots.txt file associated with it.  Examples: www.example.com or example.com.",
    DOMAIN_LABEL: "Domain",
    ADD_DOMAIN_BTN_LABEL: "MONITOR DOMAIN",
    domainPlaceHolder: "https://www.yourwebsite.com",
  },
  resetPasswordPage: {
    TITLE: "Let’s reset your password",
    DESCRIPTION:
      "Enter the email used to set up your account and we’ll send a link on how to reset your password.",
    RESET_PWD_BTN_LABEL: "RESET PASSWORD",
  },
  scheduleAuditsPage: {
    TITLE: "Set Your Schedule",
    DESKTOP_DESCRIPTION_PART_1:
      "SEO Radar alerts you to changes since the last crawl.",
    DESKTOP_DESCRIPTION_PART_2: "Choose the days and times to crawl.",
    MOBILE_DESCRIPTION:
      "SEO Radar alerts you to changes since the last crawl. Choose the days and times to crawl.",
    SCHEDULE_LABEL: "SCHEDULE ",
    TIME_ZONE_TEXT: "Time & Time Zone",
    DAYS_LABEL: "Days",
    SUNDAY_CODE: "Su",
    MONDAY_CODE: "M",
    TUESDAY_CODE: "T",
    WEDNESDAY_CODE: "W",
    THURSDAY_CODE: "Th",
    FRIDAY_CODE: "F",
    SATURDAY_CODE: "Sa",
    SUNDAY_TEXT: "Sunday",
    MONDAY_TEXT: "Monday",
    TUESDAY_TEXT: "Tuesday",
    WEDNESDAY_TEXT: "Wednesday",
    THURSDAY_TEXT: "Thursday",
    FRIDAY_TEXT: "Friday",
    SATURDAY_TEXT: "Saturday",
    AM_TEXT: "AM",
    PM_TEXT: "PM",
    TOOLTIP:
      "Get consistent monitoring at the day and time you set.  seo radar will begin fetching each URL on the schedule set below.  You can always add or edit your schedule.",
  },
  signInPage: {
    TITLE: "Sign in",
    DESCRIPTION: "Monitor your site’s latest SEO changes",
    SIGN_IN_BTN_LABEL: "SIGN IN",
    NEW_TO_SEORADAR: "New to SEO Radar?",
    GET_STARTED: "Get started!",
  },
  signUpPage: {
    freeTrialInfo: {
      TITLE: "What’s Included in the Free Trial",
      FIRST_POINT: "Full access to seo radar for 30 days",
      SECOND_POINT:
        "Monitor website changes on the URLs that are important to you",
      THIRD_POINT: "Schedule monitoring",
      FOURTH_POINT: "Side-by-side comparison of SEO focused changes",
    },
    submitSection: {
      NO_CC_REQUIRED: "No credit card needed",
      ALREADY_HAVE_ACCOUNT: "Already have an account?",
      SIGN_IN_TXT: "Sign In",
    },
    TERMS_NOT_ACCEPTED_ERR_MSG:
      "Please accept the seo radar terms of service and privacy policy",
    ACCOUNT_CREATED: "Account got created",
    START_FREE_TRIAL_TITLE: "Start Your 30-Day Free Trial",
    CREATE_ACCOUNT_TITLE: "Create your account",
    AGREE_TO_TERMS_PART_1: "I agree to the SEORADAR Inc",
    AGREE_TO_TERMS_PART_2: "terms of service",
    AGREE_TO_TERMS_PART_3: "privacy policy",
    START_FREE_TRIAL_BTN_LABEL: "START FREE TRIAL",
  },
  changePasswordPage: {
    TITLE: "Change Password",
    DESCRIPTION: "Enter a new password.",
    BTN_LABEL: "CHANGE PASSWORD",
  },
  manualAddUrlsPage: {
    PLEASE_ENTER_VALID_URL_ERROR:
      "Please enter at least one valid url for the domain",
    PLEASE_REMOVE_INVALID_URLS: "Please remove invalid urls",
    ADD_URLS_TITLE: "Add URLs",
    ADD_URLS_SUBTITLE:
      "Add and edit your list of URLs at anytime, copy and paste your URLs now.",
    COPY_AND_PASTER_URLS_LABEL: "Copy & Paste URLs",
    SAVE_NEW_URLS_TEXT: "SAVE NEW URLS",
  },
  staticPageLinks: {
    TERMS_OF_SVC: "https://www.seoradar.com/tos/",
    PRIVACY_POLICY: "https://www.seoradar.com/privacy-policy/",
  },
  httpHeaderKeys: {
    CONTENT_TYPE: "Content-Type",
    ACCESS_TOKEN: "Access-Token",
    CLIENT: "Client",
    TOKEN_TYPE: "Token-Type",
    EXPIRY: "Expiry",
    UID: "Uid",
  },
  httpHeaderValues: {
    JSON_TYPE: "application/json",
  },
  seoradarServiceLevels: {
    GOLD: "Lite",
    GOLD_ID: 646,
  },
  httpMethods: {
    POST: "POST",
    PATCH: "PATCH",
  },
  noMsg: "",
  validDomainRegEx:
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  validEmailRegEx:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  invalidEmailErrorMsg: "Please enter a valid email address",
  notRegisteredEmailErrorMsg: "Please enter your registered email address",
  emailInUseErrorMsg: "Email address is already in use",
  minPwdLengthErrorMsg: "Password must be 8 characters or more",
  minPwdLength: 8,
  minNameLength: 2,
  noNameEnteredErrorMsg: "Please enter your name",
  noPwdEnteredErrorMsg: "Please enter your password",
  emailFieldPlaceHolder: "Email",
  cssElmIDs: {
    EMAIL_FIELD: "email-field",
  },
  none: "none",
  devices: {
    desktop: "desktop",
    mobile: "mobile",
    tab: "tab",
  },
  cancelBtnLabel: "CANCEL",
  timeZoneValues: [
    {
      actual_val: "Hawaii",
      display_val: "(GMT-10:00) Hawaii",
    },
    {
      actual_val: "Alaska",
      display_val: "(GMT-09:00) Alaska",
    },
    {
      actual_val: "Pacific Time (US & Canada)",
      display_val: "(GMT-08:00) Pacific Time (US & Canada)",
    },
    {
      actual_val: "Arizona",
      display_val: "(GMT-07:00) Arizona",
    },
    {
      actual_val: "Mountain Time (US & Canada)",
      display_val: "(GMT-07:00) Mountain Time (US & Canada)",
    },
    {
      actual_val: "Central Time (US & Canada)",
      display_val: "(GMT-06:00) Central Time (US & Canada)",
    },
    {
      actual_val: "Eastern Time (US & Canada)",
      display_val: "(GMT-05:00) Eastern Time (US & Canada)",
    },
    {
      actual_val: "Indiana (East)",
      display_val: "(GMT-05:00) Indiana (East)",
    },
    {
      display_val: "-------------",
    },
    {
      actual_val: "American Samoa",
      display_val: "(GMT-11:00) American Samoa",
    },
    {
      actual_val: "International Date Line West",
      display_val: "(GMT-11:00) International Date Line West",
    },
    {
      actual_val: "Midway Island",
      display_val: "(GMT-11:00) Midway Island",
    },
    {
      actual_val: "Tijuana",
      display_val: "(GMT-08:00) Tijuana",
    },
    {
      actual_val: "Chihuahua",
      display_val: "(GMT-07:00) Chihuahua",
    },
    {
      actual_val: "Mazatlan",
      display_val: "(GMT-07:00) Mazatlan",
    },
    {
      actual_val: "Central America",
      display_val: "(GMT-06:00) Central America",
    },
    {
      actual_val: "Guadalajara",
      display_val: "(GMT-06:00) Guadalajara",
    },
    {
      actual_val: "Mexico City",
      display_val: "(GMT-06:00) Mexico City",
    },
    {
      actual_val: "Monterrey",
      display_val: "(GMT-06:00) Monterrey",
    },
    {
      actual_val: "Saskatchewan",
      display_val: "(GMT-06:00) Saskatchewan",
    },
    {
      actual_val: "Bogota",
      display_val: "(GMT-05:00) Bogota",
    },
    {
      actual_val: "Lima",
      display_val: "(GMT-05:00) Lima",
    },
    {
      actual_val: "Quito",
      display_val: "(GMT-05:00) Quito",
    },
    {
      actual_val: "Atlantic Time (Canada)",
      display_val: "(GMT-04:00) Atlantic Time (Canada)",
    },
    {
      actual_val: "Caracas",
      display_val: "(GMT-04:00) Caracas",
    },
    {
      actual_val: "Georgetown",
      display_val: "(GMT-04:00) Georgetown",
    },
    {
      actual_val: "La Paz",
      display_val: "(GMT-04:00) La Paz",
    },
    {
      actual_val: "Santiago",
      display_val: "(GMT-04:00) Santiago",
    },
    {
      actual_val: "Newfoundland",
      display_val: "(GMT-03:30) Newfoundland",
    },
    {
      actual_val: "Brasilia",
      display_val: "(GMT-03:00) Brasilia",
    },
    {
      actual_val: "Buenos Aires",
      display_val: "(GMT-03:00) Buenos Aires",
    },
    {
      actual_val: "Greenland",
      display_val: "(GMT-03:00) Greenland",
    },
    {
      actual_val: "Montevideo",
      display_val: "(GMT-03:00) Montevideo",
    },
    {
      actual_val: "Mid-Atlantic",
      display_val: "(GMT-02:00) Mid-Atlantic",
    },
    {
      actual_val: "Azores",
      display_val: "(GMT-01:00) Azores",
    },
    {
      actual_val: "Cape Verde Is.",
      display_val: "(GMT-01:00) Cape Verde Is.",
    },
    {
      actual_val: "Casablanca",
      display_val: "(GMT+00:00) Casablanca",
    },
    {
      actual_val: "Dublin",
      display_val: "(GMT+00:00) Dublin",
    },
    {
      actual_val: "Edinburgh",
      display_val: "(GMT+00:00) Edinburgh",
    },
    {
      actual_val: "Lisbon",
      display_val: "(GMT+00:00) Lisbon",
    },
    {
      actual_val: "London",
      display_val: "(GMT+00:00) London",
    },
    {
      actual_val: "Monrovia",
      display_val: "(GMT+00:00) Monrovia",
    },
    {
      actual_val: "UTC",
      display_val: "(GMT+00:00) UTC",
    },
    {
      actual_val: "Amsterdam",
      display_val: "(GMT+01:00) Amsterdam",
    },
    {
      actual_val: "Belgrade",
      display_val: "(GMT+01:00) Belgrade",
    },
    {
      actual_val: "Berlin",
      display_val: "(GMT+01:00) Berlin",
    },
    {
      actual_val: "Bern",
      display_val: "(GMT+01:00) Bern",
    },
    {
      actual_val: "Bratislava",
      display_val: "(GMT+01:00) Bratislava",
    },
    {
      actual_val: "Brussels",
      display_val: "(GMT+01:00) Brussels",
    },
    {
      actual_val: "Budapest",
      display_val: "(GMT+01:00) Budapest",
    },
    {
      actual_val: "Copenhagen",
      display_val: "(GMT+01:00) Copenhagen",
    },
    {
      actual_val: "Ljubljana",
      display_val: "(GMT+01:00) Ljubljana",
    },
    {
      actual_val: "Madrid",
      display_val: "(GMT+01:00) Madrid",
    },
    {
      actual_val: "Paris",
      display_val: "(GMT+01:00) Paris",
    },
    {
      actual_val: "Prague",
      display_val: "(GMT+01:00) Prague",
    },
    {
      actual_val: "Rome",
      display_val: "(GMT+01:00) Rome",
    },
    {
      actual_val: "Sarajevo",
      display_val: "(GMT+01:00) Sarajevo",
    },
    {
      actual_val: "Skopje",
      display_val: "(GMT+01:00) Skopje",
    },
    {
      actual_val: "Stockholm",
      display_val: "(GMT+01:00) Stockholm",
    },
    {
      actual_val: "Vienna",
      display_val: "(GMT+01:00) Vienna",
    },
    {
      actual_val: "Warsaw",
      display_val: "(GMT+01:00) Warsaw",
    },
    {
      actual_val: "West Central Africa",
      display_val: "(GMT+01:00) West Central Africa",
    },
    {
      actual_val: "Zagreb",
      display_val: "(GMT+01:00) Zagreb",
    },
    {
      actual_val: "Athens",
      display_val: "(GMT+02:00) Athens",
    },
    {
      actual_val: "Bucharest",
      display_val: "(GMT+02:00) Bucharest",
    },
    {
      actual_val: "Cairo",
      display_val: "(GMT+02:00) Cairo",
    },
    {
      actual_val: "Harare",
      display_val: "(GMT+02:00) Harare",
    },
    {
      actual_val: "Helsinki",
      display_val: "(GMT+02:00) Helsinki",
    },
    {
      actual_val: "Jerusalem",
      display_val: "(GMT+02:00) Jerusalem",
    },
    {
      actual_val: "Kaliningrad",
      display_val: "(GMT+02:00) Kaliningrad",
    },
    {
      actual_val: "Kyiv",
      display_val: "(GMT+02:00) Kyiv",
    },
    {
      actual_val: "Pretoria",
      display_val: "(GMT+02:00) Pretoria",
    },
    {
      actual_val: "Riga",
      display_val: "(GMT+02:00) Riga",
    },
    {
      actual_val: "Sofia",
      display_val: "(GMT+02:00) Sofia",
    },
    {
      actual_val: "Tallinn",
      display_val: "(GMT+02:00) Tallinn",
    },
    {
      actual_val: "Vilnius",
      display_val: "(GMT+02:00) Vilnius",
    },
    {
      actual_val: "Baghdad",
      display_val: "(GMT+03:00) Baghdad",
    },
    {
      actual_val: "Istanbul",
      display_val: "(GMT+03:00) Istanbul",
    },
    {
      actual_val: "Kuwait",
      display_val: "(GMT+03:00) Kuwait",
    },
    {
      actual_val: "Minsk",
      display_val: "(GMT+03:00) Minsk",
    },
    {
      actual_val: "Moscow",
      display_val: "(GMT+03:00) Moscow",
    },
    {
      actual_val: "Nairobi",
      display_val: "(GMT+03:00) Nairobi",
    },
    {
      actual_val: "Riyadh",
      display_val: "(GMT+03:00) Riyadh",
    },
    {
      actual_val: "St. Petersburg",
      display_val: "(GMT+03:00) St. Petersburg",
    },
    {
      actual_val: "Volgograd",
      display_val: "(GMT+03:00) Volgograd",
    },
    {
      actual_val: "Tehran",
      display_val: "(GMT+03:30) Tehran",
    },
    {
      actual_val: "Abu Dhabi",
      display_val: "(GMT+04:00) Abu Dhabi",
    },
    {
      actual_val: "Baku",
      display_val: "(GMT+04:00) Baku",
    },
    {
      actual_val: "Muscat",
      display_val: "(GMT+04:00) Muscat",
    },
    {
      actual_val: "Samara",
      display_val: "(GMT+04:00) Samara",
    },
    {
      actual_val: "Tbilisi",
      display_val: "(GMT+04:00) Tbilisi",
    },
    {
      actual_val: "Yerevan",
      display_val: "(GMT+04:00) Yerevan",
    },
    {
      actual_val: "Kabul",
      display_val: "(GMT+04:30) Kabul",
    },
    {
      actual_val: "Ekaterinburg",
      display_val: "(GMT+05:00) Ekaterinburg",
    },
    {
      actual_val: "Islamabad",
      display_val: "(GMT+05:00) Islamabad",
    },
    {
      actual_val: "Karachi",
      display_val: "(GMT+05:00) Karachi",
    },
    {
      actual_val: "Tashkent",
      display_val: "(GMT+05:00) Tashkent",
    },
    {
      actual_val: "Chennai",
      display_val: "(GMT+05:30) Chennai",
    },
    {
      actual_val: "Kolkata",
      display_val: "(GMT+05:30) Kolkata",
    },
    {
      actual_val: "Mumbai",
      display_val: "(GMT+05:30) Mumbai",
    },
    {
      actual_val: "New Delhi",
      display_val: "(GMT+05:30) New Delhi",
    },
    {
      actual_val: "Sri Jayawardenepura",
      display_val: "(GMT+05:30) Sri Jayawardenepura",
    },
    {
      actual_val: "Kathmandu",
      display_val: "(GMT+05:45) Kathmandu",
    },
    {
      actual_val: "Almaty",
      display_val: "(GMT+06:00) Almaty",
    },
    {
      actual_val: "Astana",
      display_val: "(GMT+06:00) Astana",
    },
    {
      actual_val: "Dhaka",
      display_val: "(GMT+06:00) Dhaka",
    },
    {
      actual_val: "Urumqi",
      display_val: "(GMT+06:00) Urumqi",
    },
    {
      actual_val: "Rangoon",
      display_val: "(GMT+06:30) Rangoon",
    },
    {
      actual_val: "Bangkok",
      display_val: "(GMT+07:00) Bangkok",
    },
    {
      actual_val: "Hanoi",
      display_val: "(GMT+07:00) Hanoi",
    },
    {
      actual_val: "Jakarta",
      display_val: "(GMT+07:00) Jakarta",
    },
    {
      actual_val: "Krasnoyarsk",
      display_val: "(GMT+07:00) Krasnoyarsk",
    },
    {
      actual_val: "Novosibirsk",
      display_val: "(GMT+07:00) Novosibirsk",
    },
    {
      actual_val: "Beijing",
      display_val: "(GMT+08:00) Beijing",
    },
    {
      actual_val: "Chongqing",
      display_val: "(GMT+08:00) Chongqing",
    },
    {
      actual_val: "Hong Kong",
      display_val: "(GMT+08:00) Hong Kong",
    },
    {
      actual_val: "Irkutsk",
      display_val: "(GMT+08:00) Irkutsk",
    },
    {
      actual_val: "Kuala Lumpur",
      display_val: "(GMT+08:00) Kuala Lumpur",
    },
    {
      actual_val: "Perth",
      display_val: "(GMT+08:00) Perth",
    },
    {
      actual_val: "Singapore",
      display_val: "(GMT+08:00) Singapore",
    },
    {
      actual_val: "Taipei",
      display_val: "(GMT+08:00) Taipei",
    },
    {
      actual_val: "Ulaanbaatar",
      display_val: "(GMT+08:00) Ulaanbaatar",
    },
    {
      actual_val: "Osaka",
      display_val: "(GMT+09:00) Osaka",
    },
    {
      actual_val: "Sapporo",
      display_val: "(GMT+09:00) Sapporo",
    },
    {
      actual_val: "Seoul",
      display_val: "(GMT+09:00) Seoul",
    },
    {
      actual_val: "Tokyo",
      display_val: "(GMT+09:00) Tokyo",
    },
    {
      actual_val: "Yakutsk",
      display_val: "(GMT+09:00) Yakutsk",
    },
    {
      actual_val: "Adelaide",
      display_val: "(GMT+09:30) Adelaide",
    },
    {
      actual_val: "Darwin",
      display_val: "(GMT+09:30) Darwin",
    },
    {
      actual_val: "Brisbane",
      display_val: "(GMT+10:00) Brisbane",
    },
    {
      actual_val: "Canberra",
      display_val: "(GMT+10:00) Canberra",
    },
    {
      actual_val: "Guam",
      display_val: "(GMT+10:00) Guam",
    },
    {
      actual_val: "Hobart",
      display_val: "(GMT+10:00) Hobart",
    },
    {
      actual_val: "Melbourne",
      display_val: "(GMT+10:00) Melbourne",
    },
    {
      actual_val: "Port Moresby",
      display_val: "(GMT+10:00) Port Moresby",
    },
    {
      actual_val: "Sydney",
      display_val: "(GMT+10:00) Sydney",
    },
    {
      actual_val: "Vladivostok",
      display_val: "(GMT+10:00) Vladivostok",
    },
    {
      actual_val: "Magadan",
      display_val: "(GMT+11:00) Magadan",
    },
    {
      actual_val: "New Caledonia",
      display_val: "(GMT+11:00) New Caledonia",
    },
    {
      actual_val: "Solomon Is.",
      display_val: "(GMT+11:00) Solomon Is.",
    },
    {
      actual_val: "Srednekolymsk",
      display_val: "(GMT+11:00) Srednekolymsk",
    },
    {
      actual_val: "Auckland",
      display_val: "(GMT+12:00) Auckland",
    },
    {
      actual_val: "Fiji",
      display_val: "(GMT+12:00) Fiji",
    },
    {
      actual_val: "Kamchatka",
      display_val: "(GMT+12:00) Kamchatka",
    },
    {
      actual_val: "Marshall Is.",
      display_val: "(GMT+12:00) Marshall Is.",
    },
    {
      actual_val: "Wellington",
      display_val: "(GMT+12:00) Wellington",
    },
    {
      actual_val: "Chatham Is.",
      display_val: "(GMT+12:45) Chatham Is.",
    },
    {
      actual_val: "Nuku'alofa",
      display_val: "(GMT+13:00) Nuku'alofa",
    },
    {
      actual_val: "Samoa",
      display_val: "(GMT+13:00) Samoa",
    },
    {
      actual_val: "Tokelau Is.",
      display_val: "(GMT+13:00) Tokelau Is.",
    },
  ],
};

export default strings;
