/** @format */

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import "./fonts/Graphik-Regular.ttf";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import SignInPage from "./components/SignInPage/index";
import ChangePasswordPage from "./components/ChangePasswordPage/index";

import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
  <CookiesProvider>
    <GoogleOAuthProvider clientId="148742868963-abfvi2br0nq3sul6u5dk0fm941qqr1e8.apps.googleusercontent.com">
      <Router>
        <Route path="/onboarding/reset_password" component={ChangePasswordPage} />
        <Route path="/onboarding/sign_up" component={App} />
        <Route path="/onboarding/log_in" component={SignInPage} />
      </Router>
    </GoogleOAuthProvider>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
