/** @format */

import SignupPage from "./components/SignupPage";
import strings from "./strings";

const { seoradarServiceLevels } = strings;

const App = ({ match, location }) => {
  let service_level = seoradarServiceLevels.GOLD; // default
  let service_level_id = seoradarServiceLevels.GOLD_ID; // default

  // Algorithm:
  // make API Call to check if user is logged in or not
  // if yes redirect to his existing account dashboard
  // else continue with sign up
  const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/sessions/check_if_session_exists`;
  var requestOptions = { method: "GET" };
  fetch(apiEndPoint, requestOptions).then((response) => {
    if (response.status === 200) {
      window.location.href = process.env.REACT_APP_API_URI;
    }
  });

  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  let queryParamStr = location.search;
  if (queryParamStr !== "") {
    let list = queryParamStr.split("?")[1].split("&");
    for (let i = 0; i < list.length; i++) {
      let key = list[i].split("=")[0];
      let val = list[i].split("=")[1];
      if (key === "service_level") {
        service_level = val;
      }
      if (key === "service_level_id") {
        service_level_id = val;
      }
    }
  } else {
  }
  return (
    <SignupPage
      service_level={service_level}
      service_level_id={service_level_id}
    ></SignupPage>
  );
};
export default App;
