/** @format */

import "./index.css";
import React, { useState, useEffect } from "react";
import strings from "../../strings";
import UpgradePlan from "./../UpgradePlan";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import PayoffPage from "./../PayoffPage";

const {
  scheduleAuditsPage,
  httpHeaderKeys,
  httpMethods,
  httpHeaderValues,
  timeZoneValues,
} = strings;

const ScheduleOnboardingPage = (props) => {
  const [hourTime, setHourTime] = useState("06");
  const [minuteTime, setMinuteTime] = useState("00");
  const [enableAM, setEnableAM] = useState(true);

  const [defaultTimeZone, setDefaultTimezone] = useState("");
  const [selectedTimeZone, setSelectedTimezone] = useState("");
  const timeZones = timeZoneValues;
  const [showTimeZones, setShowTimeZones] = useState(false);
  const [seoradarServiceLevelListNew, setSeoradarServiceLevelList] = useState();
  const [defaultSchedule, setDefaultSchedule] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);
  const [totalScheduleCount, setTotalScheduleCount] = useState(0);

  const [dayEnabled, setDayEnabled] = useState(false);
  const [svlEnabled, setSvlEnabled] = useState("");
  const [schedulePageFlag, setSchedulePageFlag] = useState(true);
  const [editEnabled, setEditEnabled] = useState(false);

  const [error, setError] = useState("");
  const [auditNumberExceedError, setAuditNumberExceedError] = useState("");
  const [noDaysSelectedError, setNoDaysSelectedError] = useState("");

  const [currentPlan, setCurrentPlan] = useState("");
  const [currentPlanAuditCount, setCurrentPlanAuditCount] = useState();

  const [nextPlan, setNextPlan] = useState("");
  const [nextPlanServiceId, setNextPlanServiceId] = useState();
  const [nextPlanAuditCount, setNextPlanAuditCount] = useState();

  const [customPlan, setCustomPlan] = useState(false);

  const [nextScreenBtnDisabled, setNextScreenBtnDisabled] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
    myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
    myHeaders.append(httpHeaderKeys.CLIENT, props.client);
    myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
    myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
    myHeaders.append(httpHeaderKeys.UID, props.uid);

    var requestOptions = {
      method: httpMethods.GET,
      headers: myHeaders,
    };
    const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/accounts/list_of_service_levels`;

    fetch(apiEndPoint, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        const resultJson = JSON.parse(result);

        let seoradarServiceLevelList = resultJson.message;
        setSeoradarServiceLevelList(seoradarServiceLevelList[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (seoradarServiceLevelListNew) {
      let keys = Object.keys(seoradarServiceLevelListNew);
      console.log(`service_level: ${props.service_level}`);
      console.log(`service_level_id: ${props.service_level_id}`);
      let chosen_plan = props.service_level;
      let index_of_curr_plan = -1;
      let index_of_next_plan = -1;
      for (let i = 0; i < keys.length - 1; i++) {
        if (keys[i] === chosen_plan) {
          index_of_curr_plan = i;
          index_of_next_plan = i + 1;
        }
      }
      setCurrentPlan(chosen_plan);
      setNextPlan(keys[index_of_next_plan]);
      setCurrentPlanAuditCount(
        seoradarServiceLevelListNew[keys[index_of_curr_plan]].audits_per_week
      );
      setNextPlanServiceId(
        seoradarServiceLevelListNew[keys[index_of_next_plan]].service_level_id
      );
      setNextPlanAuditCount(
        seoradarServiceLevelListNew[keys[index_of_next_plan]].audits_per_week
      );
      if (chosen_plan === "Max") {
        setCustomPlan(true);
      }
      let currentPlanauditCount =
        seoradarServiceLevelListNew[keys[index_of_curr_plan]].audits_per_week;
      setDaysSelected(
        currentPlanauditCount === 1 ? ["Monday"] : ["Monday", "Friday"]
      );
    }
  }, [seoradarServiceLevelListNew]);

  const defaultSelectedDays =
    currentPlanAuditCount === 1 ? ["Monday"] : ["Monday", "Friday"];
  const [daysSelected, setDaysSelected] = useState(defaultSelectedDays);

  useEffect(() => {
    let newDefaultSchedule = ["default"];
    let newDefaultTimeZone = "";
    let selectedDefaultDays = defaultSelectedDays;

    let gmt = new Date().toString().split(" ")[5];
    let text = gmt.substring(0, 6).concat(":").concat(gmt.substring(6, 8));

    for (let i = 0; i < timeZones.length; i++) {
      let value = timeZones[i];

      if (value.display_val.includes(text) && newDefaultTimeZone === "") {
        newDefaultTimeZone = value.actual_val;
      }
    }

    setDefaultTimezone(newDefaultTimeZone);
    setSelectedTimezone(newDefaultTimeZone);

    newDefaultSchedule[4] = hourTime;
    newDefaultSchedule[5] = minuteTime;
    newDefaultSchedule[6] = newDefaultTimeZone;
    newDefaultSchedule[7] = selectedDefaultDays;
    newDefaultSchedule[8] = enableAM;

    setDefaultSchedule(newDefaultSchedule);
    setScheduleList([newDefaultSchedule]);
  }, [timeZones]);

  useEffect(() => {
    if (scheduleList.length === 1) {
      let newDefaultSchedule = ["default"];
      let selectedDefaultDays = defaultSelectedDays;
      let selectedDefaultTimeZone = defaultTimeZone;

      newDefaultSchedule[4] = hourTime;
      newDefaultSchedule[5] = minuteTime;
      newDefaultSchedule[6] = selectedDefaultTimeZone;
      newDefaultSchedule[7] = selectedDefaultDays;
      newDefaultSchedule[8] = enableAM;

      setDefaultSchedule(newDefaultSchedule);
      setScheduleList([newDefaultSchedule]);
    }
  }, [hourTime, minuteTime]);

  const getActualScheduleCount = (list) => {
    let count = 0;

    for (let i = 0; i < list.length; i++) {
      if (list[i][0] === "") {
        count = count + list[i][7].length;
      } else {
        count = count + daysSelected.length;
      }
    }
    return count;
  };

  let audits = getActualScheduleCount(scheduleList);
  let auditCountExceeded = audits > currentPlanAuditCount ? true : false;
  let noDaysSelected = daysSelected.length > 0 ? false : true;
  let auditLimitReached = audits >= currentPlanAuditCount;

  useEffect(() => {
    if (auditCountExceeded) {
      setAuditNumberExceedError(
        "*This selection exceeds the number of days included in your current plan."
      );
    } else {
      setAuditNumberExceedError("");
    }
  }, [auditCountExceeded]);

  useEffect(() => {
    if (noDaysSelected) {
      setNoDaysSelectedError("Please select the day for your schedule");
    } else {
      setNoDaysSelectedError("");
    }
  }, [noDaysSelected]);

  const getDefaultSchedule = (schedule, index) => {
    return (
      <div>
        <div class="row">
          <div class="col-sm-2 col-xs-2 col-md-5 col-lg-5 col-xl-5"> </div>
          <div class="col-sm-8 col-xs-8 col-md-4 col-lg-3 col-xl-3">
            <p className={"schedule_default_label"}>
              {scheduleAuditsPage.SCHEDULE_LABEL + (index + 1)}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 col-xs-12 col-md-3 col-lg-3 col-xl-3">
            <p className={"time_zone_text"}>
              {scheduleAuditsPage.TIME_ZONE_TEXT}
            </p>
          </div>
          <div class="form col-sm-5 col-xs-12 col-md-5 col-lg-4 col-xl-4 time-zone-div">
            <div class="cursor">
              <div class="time-scheduled">
                <input
                  id="appt-time"
                  type="number"
                  name="appt-time"
                  value={hourTime}
                  min="0"
                  max="11"
                  step="1"
                  onChange={handleHourChange}
                  className={"time-scheduled-hr"}
                ></input>
                <span className={"time-scheduled-separtor"}>{":"}</span>
                <input
                  id="appt-time"
                  type="number"
                  name="appt-time"
                  value={minuteTime}
                  min="0"
                  max="59"
                  step="1"
                  onChange={handleMinChange}
                  className={"time-scheduled-min"}
                ></input>
              </div>
              <input
                type="button"
                name="appt-time"
                value={scheduleAuditsPage.AM_TEXT}
                onClick={handleAMClick}
                className={enableAM ? "am-button" : "pm-button"}
              ></input>
              <input
                type="button"
                name="appt-time"
                value={scheduleAuditsPage.PM_TEXT}
                onClick={handleAMClick}
                className={enableAM ? "pm-button" : "am-button"}
              ></input>
            </div>
          </div>
          <div class="col-sm-4 col-xs-12 col-md-4 col-lg-4 col-xl-4 center-aligned-timezone">
            <div className={"time-zone-section"}>
              {!showTimeZones ? (
                <div
                  className={"plain-div"}
                  onClick={() => setShowTimeZones(!showTimeZones)}
                >
                  <Paper className={"paper"}>
                    <MenuList className={"menu-list"}>
                      <MenuItem className={"main-menu-item"}>
                        <p className={"time-zone-text"}>{selectedTimeZone}</p>
                        <img
                          className={"select-down-icon-style"}
                          src="/images/arrow-down.png"
                          onClick={() => setShowTimeZones(!showTimeZones)}
                          alt="icon"
                        />
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </div>
              ) : (
                <div
                  className={"confirm-div"}
                  onClick={() => setShowTimeZones(!showTimeZones)}
                >
                  <Paper className={"paper"}>
                    <MenuList className={"menu-list"}>
                      {timeZones &&
                        timeZones.map((val) => (
                          <MenuItem
                            className={"main-menu-item"}
                            onClick={() =>
                              val.display_val === "-------------"
                                ? null
                                : handleTimeZoneClick(val.actual_val)
                            }
                          >
                            {val.display_val === "-------------"
                              ? val.display_val
                              : val.actual_val}
                          </MenuItem>
                        ))}
                    </MenuList>
                  </Paper>
                </div>
              )}
            </div>
          </div>
        </div>

        <div class="row days-margin">
          <div class="col-sm-3 col-xs-12 col-md-3 col-lg-4 col-xl-4">
            <p className={"days_text"}>{scheduleAuditsPage.DAYS_LABEL}</p>
          </div>
          <div class="form col-sm-7 col-xs-12 col-md-9 col-lg-8 col-xl-8 center-aligned-div days-div">
            <input
              type="button"
              name="sunday"
              value={scheduleAuditsPage.SUNDAY_CODE}
              onClick={() => handleWeekDayClick(scheduleAuditsPage.SUNDAY_TEXT)}
              className={
                daysSelected.includes(scheduleAuditsPage.SUNDAY_TEXT)
                  ? auditCountExceeded
                    ? "week-day-button-selected-error week-day-button"
                    : "week-day-button-selected week-day-button"
                  : "week-day-button-unselected week-day-button"
              }
            ></input>
            <input
              type="button"
              name="monday"
              value={scheduleAuditsPage.MONDAY_CODE}
              onClick={() => handleWeekDayClick(scheduleAuditsPage.MONDAY_TEXT)}
              className={
                daysSelected.includes(scheduleAuditsPage.MONDAY_TEXT)
                  ? auditCountExceeded
                    ? "week-day-button-selected-error week-day-button"
                    : "week-day-button-selected week-day-button"
                  : "week-day-button-unselected week-day-button"
              }
            ></input>
            <input
              type="button"
              name="tuesday"
              value={scheduleAuditsPage.TUESDAY_CODE}
              onClick={() =>
                handleWeekDayClick(scheduleAuditsPage.TUESDAY_TEXT)
              }
              className={
                daysSelected.includes(scheduleAuditsPage.TUESDAY_TEXT)
                  ? auditCountExceeded
                    ? "week-day-button-selected-error week-day-button"
                    : "week-day-button-selected week-day-button"
                  : "week-day-button-unselected week-day-button"
              }
            ></input>
            <input
              type="button"
              name="wednesday"
              value={scheduleAuditsPage.WEDNESDAY_CODE}
              onClick={() =>
                handleWeekDayClick(scheduleAuditsPage.WEDNESDAY_TEXT)
              }
              className={
                daysSelected.includes(scheduleAuditsPage.WEDNESDAY_TEXT)
                  ? auditCountExceeded
                    ? "week-day-button-selected-error week-day-button"
                    : "week-day-button-selected week-day-button"
                  : "week-day-button-unselected week-day-button"
              }
            ></input>
            <input
              type="button"
              name="thursday"
              value={scheduleAuditsPage.THURSDAY_CODE}
              onClick={() =>
                handleWeekDayClick(scheduleAuditsPage.THURSDAY_TEXT)
              }
              className={
                daysSelected.includes(scheduleAuditsPage.THURSDAY_TEXT)
                  ? auditCountExceeded
                    ? "week-day-button-selected-error week-day-button"
                    : "week-day-button-selected week-day-button"
                  : "week-day-button-unselected week-day-button"
              }
            ></input>
            <input
              type="button"
              name="friday"
              value={scheduleAuditsPage.FRIDAY_CODE}
              onClick={() => handleWeekDayClick(scheduleAuditsPage.FRIDAY_TEXT)}
              className={
                daysSelected.includes(scheduleAuditsPage.FRIDAY_TEXT)
                  ? auditCountExceeded
                    ? "week-day-button-selected-error week-day-button"
                    : "week-day-button-selected week-day-button"
                  : "week-day-button-unselected week-day-button"
              }
            ></input>
            <input
              type="button"
              name="saturday"
              value={scheduleAuditsPage.SATURDAY_CODE}
              onClick={() =>
                handleWeekDayClick(scheduleAuditsPage.SATURDAY_TEXT)
              }
              className={
                daysSelected.includes(scheduleAuditsPage.SATURDAY_TEXT)
                  ? auditCountExceeded
                    ? "week-day-button-selected-error week-day-button"
                    : "week-day-button-selected week-day-button"
                  : "week-day-button-unselected week-day-button"
              }
            ></input>
          </div>
        </div>

        {editEnabled ? (
          <div class="row">
            <div class="col-sm-4 col-md-5 col-lg-5 col-xl-5"></div>
            <div class="col-sm-5 col-md-5 col-lg-5 col-xl-5 display-div">
              <button
                class={"delete-schedule-text"}
                onClick={() => handleDelete(schedule, index)}
              >
                {"delete schedule"}
              </button>{" "}
              <button
                class={"update-schedule-text"}
                onClick={() => updateSchedule(schedule, index)}
              >
                {"update schedule"}
              </button>
            </div>
          </div>
        ) : (
          <div class="row">
            <div class="col-sm-4 col-md-5 col-lg-5 col-xl-5"></div>
            <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3 display-delete">
              <button
                class={"delete-schedule-text"}
                onClick={() => handleDelete(schedule, index)}
              >
                {"delete schedule"}
              </button>{" "}
            </div>
            <div class="col-sm-5 col-md-4 col-lg-3 col-xl-3"></div>
          </div>
        )}
        {!error ? (
          auditNumberExceedError ? (
            <div className={"error-text-div"}>
              <p className={"error-text-message"}>{auditNumberExceedError}</p>
            </div>
          ) : (
            <div className={"error-text-div"}>
              <p className={"error-text-message"}>{noDaysSelectedError}</p>
            </div>
          )
        ) : (
          <div className={"error-text-div"}>
            <p className={"error-text-message"}>{error}</p>
          </div>
        )}
        {editEnabled ? null : (
          <div class="row">
            <div class="col-sm-3 col-md-4 col-lg-4 col-xl-4"> </div>
            <div class="buttons-view">
              <input
                disabled={nextScreenBtnDisabled}
                type="button"
                name="save"
                value="NEXT >"
                onClick={() => handleSave()}
                className={
                  noDaysSelected || error
                    ? "save-button-disabled"
                    : "save-button"
                }
              ></input>
              <input
                type="button"
                name="sunday"
                value={"ADD ANOTHER SCHEDULE"}
                className={
                  noDaysSelected || auditLimitReached || error
                    ? "add-schedule-button-disabled"
                    : "add-schedule-button"
                }
                onClick={() =>
                  noDaysSelected || auditLimitReached || error
                    ? null
                    : onClickAddSchedule()
                }
              ></input>
            </div>
          </div>
        )}
        {scheduleList.length - 1 === index ? null : <hr class="hr"></hr>}
      </div>
    );
  };

  const handleHourChange = (e) => {
    setError("");
    let val = e.target.value;
    let hour = 0;

    hour = val.substring(0, 2);

    if (parseInt(hour) > 12) {
      let multiple = parseInt(hour) / 12;
      let formatedHour = parseInt(hour) - 12 * Math.floor(multiple);

      formatedHour = formatedHour.toString();
      if (formatedHour.length === 1) {
        hour = `${"0"}${formatedHour}`;
      } else {
        hour = formatedHour;
      }
    }

    if (!hour) {
      setError("Please enter a valid Time");
    }

    setHourTime(hour);
  };

  const handleMinChange = (e) => {
    setError("");
    let val = e.target.value;
    let minute = 0;

    minute = val.substring(0, 2);

    if (parseInt(minute) > 60) {
      let multiple = parseInt(minute) / 60;
      let formatedHour = parseInt(minute) - 60 * Math.floor(multiple);

      formatedHour = formatedHour.toString();
      if (formatedHour.length === 1) {
        minute = `${"0"}${formatedHour}`;
      } else {
        minute = formatedHour;
      }
    }

    if (!minute) {
      setError("Please enter a valid Time");
    }
    setMinuteTime(minute);
  };

  const handleAMClick = () => {
    setEnableAM(!enableAM);
  };

  const handleWeekDayClick = (weekdayName) => {
    let newList = daysSelected;

    if (newList.includes(weekdayName)) {
      newList.splice(newList.indexOf(weekdayName), 1);
    } else {
      newList.push(weekdayName);
    }

    // getActualScheduleCount;

    setDayEnabled(!dayEnabled);
    setDaysSelected(newList);
  };

  const handleTimeZoneClick = (val) => {
    setSelectedTimezone(val);
    setDefaultTimezone(val);
  };

  const getNumberValueOfday = (day) => {
    let value = 0;

    switch (day) {
      case scheduleAuditsPage.MONDAY_TEXT:
        value = 1;
        break;
      case scheduleAuditsPage.TUESDAY_TEXT:
        value = 2;
        break;
      case scheduleAuditsPage.WEDNESDAY_TEXT:
        value = 3;
        break;
      case scheduleAuditsPage.THURSDAY_TEXT:
        value = 4;
        break;
      case scheduleAuditsPage.FRIDAY_TEXT:
        value = 5;
        break;
      case scheduleAuditsPage.SATURDAY_TEXT:
        value = 6;
        break;
      default:
        return 0;
    }
    return value;
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const digitCheckNumber = (number) => {
    if (number.length === 1) {
      number = `${"0"}${number}`;
    }
    return number;
  };

  const onClickAddSchedule = () => {
    if (!auditCountExceeded && !noDaysSelected && !error) {
      let newScheduleList = [];
      let date = new Date();
      let added = false;

      let hour = digitCheckNumber(hourTime);
      let minute = digitCheckNumber(minuteTime);

      let newTimezone = selectedTimeZone;
      let selectedDays = daysSelected;

      if (scheduleList.length === 1) {
        let schedule = scheduleList[0];
        let newSchedule = [];

        if (schedule[0] === "default") {
          newSchedule[0] = "";
          newSchedule[1] = date.getFullYear();
          newSchedule[2] = date.getMonth() + 1;
          newSchedule[3] = date.getDate();
          newSchedule[4] = digitCheckNumber(schedule[4]);
          newSchedule[5] = digitCheckNumber(schedule[5]);
          newSchedule[6] = newTimezone;
          newSchedule[7] = daysSelected;
          newSchedule[8] = schedule[8];

          newScheduleList.push(newSchedule);
          added = true;
        }
      }

      for (let i = 0; i < scheduleList.length; i++) {
        let schedule = scheduleList[i];

        if (
          schedule[0] === "" &&
          schedule[1] === date.getFullYear() &&
          schedule[2] === date.getMonth() + 1 &&
          schedule[3] === date.getDate() &&
          schedule[4] === hour &&
          schedule[5] === minute &&
          schedule[6] === newTimezone &&
          schedule[8] === enableAM
        ) {
          Array.prototype.push.apply(schedule[7], selectedDays);
          var unique = schedule[7].filter(onlyUnique);
          schedule[7] = unique;
          scheduleList[i] = schedule;
          added = true;
        }
      }

      if (!added) {
        let newSchedule = [];
        let schedule = defaultSchedule;

        if (schedule[0] === "default") {
          newSchedule[0] = "";
          newSchedule[1] = date.getFullYear();
          newSchedule[2] = date.getMonth() + 1;
          newSchedule[3] = date.getDate();
          newSchedule[4] = hour;
          newSchedule[5] = minute;
          newSchedule[6] = newTimezone;
          newSchedule[7] = selectedDays;
          newSchedule[8] = enableAM;
        }
        newScheduleList.push(newSchedule);
      }

      Array.prototype.push.apply(newScheduleList, scheduleList);
      setScheduleList(newScheduleList);

      setHourTime("06");
      setMinuteTime("00");
      setDaysSelected(defaultSelectedDays);
      setSelectedTimezone(defaultTimeZone);

      setTotalScheduleCount(getActualScheduleCount(newScheduleList));
      return newScheduleList;
    }
  };

  const updateSchedule = (scheduleTobeUpdated, index) => {
    let newList = scheduleList;
    newList.splice(index, 1);

    let added = false;
    let newSchedule = [];

    let hour = digitCheckNumber(hourTime);
    let minute = digitCheckNumber(minuteTime);

    for (let i = 0; i < scheduleList.length; i++) {
      let scheduleFromList = scheduleList[i];

      if (
        scheduleFromList[4] === hour &&
        scheduleFromList[5] === minute &&
        scheduleFromList[6] === selectedTimeZone &&
        scheduleFromList[8] === enableAM
      ) {
        Array.prototype.push.apply(scheduleFromList[7], daysSelected);
        var unique = scheduleFromList[7].filter(onlyUnique);
        scheduleFromList[0] = "";
        scheduleFromList[7] = unique;
        newSchedule.push(scheduleFromList);
        added = true;
      }
    }

    if (!added) {
      newSchedule[0] = "";
      newSchedule[1] = scheduleTobeUpdated[1];
      newSchedule[2] = scheduleTobeUpdated[2];
      newSchedule[3] = scheduleTobeUpdated[3];
      newSchedule[4] = hour;
      newSchedule[5] = minute;
      newSchedule[6] = selectedTimeZone;
      newSchedule[7] = daysSelected;
      newSchedule[8] = enableAM;
      newList.push(newSchedule);
    }

    newList.push(defaultSchedule);

    setHourTime("06");
    setMinuteTime("00");
    setDaysSelected(defaultSelectedDays);
    setSelectedTimezone(defaultTimeZone);

    setEditEnabled(false);
  };

  const handleEdit = (schedule, index) => {
    let newList = scheduleList;
    schedule[0] = "default";
    newList.splice(newList.length - 1, 1);

    setHourTime(schedule[4]);
    setMinuteTime(schedule[5]);

    setEnableAM(schedule[8]);
    let days = schedule[7];
    setDaysSelected(days);
    setEditEnabled(true);
    setSelectedTimezone(schedule[6]);
    setDefaultTimezone(schedule[6]);
  };

  const handleDelete = (schedule, index) => {
    let newList = scheduleList;
    newList.splice(index, 1);
    newList.push(defaultSchedule);

    setHourTime("06");
    setMinuteTime("00");
    setDaysSelected(["Monday", "Friday"]);
    setSelectedTimezone(defaultTimeZone);
    setEditEnabled(false);
  };

  const handleUpgradeLevel = () => {
    let UpgradePlan;
    let UpgradePlanIndex;
    let nextUpgradePlan;
    let nextUpgradePlanIndex;

    let list = Object.values(seoradarServiceLevelListNew);
    let newPlanIndex = 0;
    let keys = Object.keys(seoradarServiceLevelListNew);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i] === currentPlan) {
        newPlanIndex = i;
      }
    }

    if (list.length > newPlanIndex + 2 && list.length !== newPlanIndex + 1) {
      UpgradePlanIndex = newPlanIndex + 1;
      UpgradePlan = list[newPlanIndex + 1];
    }

    if (list.length > newPlanIndex + 2 && list.length !== newPlanIndex + 2) {
      nextUpgradePlanIndex = newPlanIndex + 2;
      nextUpgradePlan = list[newPlanIndex + 2];
    } else {
      UpgradePlan = "CUSTOMIZE";
    }

    let upgradeServiceLevelName = keys[UpgradePlanIndex];
    let upgradeServiceLevelObj = UpgradePlan;
    let upgradeServiceLevelId = UpgradePlan.service_level_id;

    let nextServiceLevelName = keys[nextUpgradePlanIndex];
    let nextServiceLevelObj = nextUpgradePlan;

    if (nextServiceLevelName === keys[keys.length - 1]) {
      setCustomPlan(true);
    }

    if (upgradeServiceLevelName !== keys[keys.length - 1]) {
      var myHeaders = new Headers();
      myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
      myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
      myHeaders.append(httpHeaderKeys.CLIENT, props.client);
      myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
      myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
      myHeaders.append(httpHeaderKeys.UID, props.uid);

      var requestBody = JSON.stringify({
        service_level_name: upgradeServiceLevelName,
        service_level_id: upgradeServiceLevelId,
      });

      var requestOptions = {
        method: httpMethods.POST,
        headers: myHeaders,
        body: requestBody,
      };
      const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/accounts/upgrade_service_level`;
      fetch(apiEndPoint, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.text();
          }
        })
        .then(async (result) => {
          const resultJson = JSON.parse(result);
          let message = resultJson.message;
          if (message === "Succesfully upgraded the service level!") {
            setCurrentPlan(upgradeServiceLevelName);
            setCurrentPlanAuditCount(upgradeServiceLevelObj["audits_per_week"]);

            setNextPlan(nextServiceLevelName);
            setNextPlanAuditCount(nextServiceLevelObj["audits_per_week"]);
            setNextPlanServiceId(nextServiceLevelObj["service_level_id"]);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setCurrentPlan(upgradeServiceLevelName);
      setCurrentPlanAuditCount(upgradeServiceLevelObj["audits_per_week"]);

      setNextPlan(nextServiceLevelName);
      setNextPlanAuditCount(nextServiceLevelObj["audits_per_week"]);
      setCustomPlan(true);
    }
  };

  const formatHour = (hour, enabledAM) => {
    let formattedHour =
      hour === "00"
        ? enableAM
          ? "12"
          : "24"
        : enableAM
          ? hour
          : (parseInt(hour) + 12).toString();

    return formattedHour;
  };

  const handleSave = () => {
    if (!auditNumberExceedError && !error && !noDaysSelectedError) {
      let scheduleList = onClickAddSchedule();
      let newList = scheduleList;
      newList.splice(newList.length - 1, 1);

      let newScheduleList = [];

      let newHour = formatHour(hourTime, enableAM);

      for (let i = 0; i < scheduleList.length; i++) {
        let schedule = scheduleList[i];
        let date = new Date();

        let hour = formatHour(schedule[4], schedule[8]);

        if (schedule[0] === "default") {
          let scheduleArray = [
            "",
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            newHour,
            minuteTime,
            selectedTimeZone,
            getNumberValueOfday(daysSelected[0]),
            enableAM,
          ];
          newScheduleList.push(scheduleArray);
        } else {
          let days = schedule[7];
          if (days.length > 1) {
            for (let i = 0; i < days.length; i++) {
              let scheduleArray = [
                "",
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate(),
                hour,
                schedule[5],
                schedule[6],
                getNumberValueOfday(days[i]),
                schedule[8],
              ];
              newScheduleList.push(scheduleArray);
            }
          } else {
            let scheduleArray = [
              "",
              date.getFullYear(),
              date.getMonth() + 1,
              date.getDate(),
              hour,
              schedule[5],
              schedule[6],
              getNumberValueOfday(days[0]),
              schedule[8],
            ];
            newScheduleList.push(scheduleArray);
          }
        }
      }

      createRequest(newScheduleList);
    }
  };

  const createRequest = (newScheduleList) => {
    let audits_attribute = {};
    let schedulesObjectList = {};

    for (let i = 0; i < newScheduleList.length; i++) {
      let schedule = newScheduleList[i];
      let date = new Date();
      let timestamp = date.getTime() + i;

      let scheduleObject = {
        _destroy: "",
        "time(1i)": schedule[1].toString(),
        "time(2i)": schedule[2].toString(),
        "time(3i)": schedule[3].toString(),
        "time(4i)": schedule[4],
        "time(5i)": schedule[5],
        zone: schedule[6],
        zone_wday: schedule[7],
      };

      schedulesObjectList[timestamp] = scheduleObject;
    }

    audits_attribute["audit_schedules_attributes"] = schedulesObjectList;
    sendSchedules(audits_attribute);
  };

  const sendSchedules = (audits_attribute) => {
    if (!error) {
      var myHeaders = new Headers();
      myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
      myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
      myHeaders.append(httpHeaderKeys.CLIENT, props.client);
      myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
      myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
      myHeaders.append(httpHeaderKeys.UID, props.uid);

      var requestBody = JSON.stringify({
        audit_schedules: audits_attribute,
        id: props.websiteId,
      });

      var requestOptions = {
        method: httpMethods.POST,
        headers: myHeaders,
        body: requestBody,
      };
      const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/websites/audit_schedules`;
      setNextScreenBtnDisabled(true);
      fetch(apiEndPoint, requestOptions)
        .then((response) => {
          return response.text();
        })
        .then(async (result) => {
          const resultJson = JSON.parse(result);
          let message = resultJson.message;
          let svl_enabled = resultJson.svl_enabled;
          let error = resultJson.error;
          if (message === "Audit Scheduled") {
            setSvlEnabled(svl_enabled);
            setSchedulePageFlag(false);
          } else if (error) {
            setError(error);
            setNextScreenBtnDisabled(false);
            setScheduleList(scheduleList);
          }
        })
        .catch((error) => {
          // This case executed when internet was turned off
          // observed that the UI broke, there was no NEXT button
          console.log(`Error msg: ${error.message}`);
        });
    }
  };

  return (
    <>
      {schedulePageFlag ? (
        <div>
          <Header screenNumber={4}></Header>
          <div className="main-container">
            <p className={"set_schedule_text"}>{scheduleAuditsPage.TITLE}</p>
            <div className={"desktop-view"}>
              <p className={"schedule_text11"}>
                {scheduleAuditsPage.DESKTOP_DESCRIPTION_PART_1}
              </p>
              <p className={"schedule_text12"}>
                {scheduleAuditsPage.DESKTOP_DESCRIPTION_PART_2}
              </p>
            </div>
            <div className={"mobile-view"}>
              <p className={"schedule_text11"}>
                {scheduleAuditsPage.MOBILE_DESCRIPTION}
              </p>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-lg-1 col-xl-1"></div>
                <div class="col-sm-12 col-xs-12 col-md-9 col-lg-8 col-xl-8 mid-div">
                  {scheduleList &&
                    scheduleList.map((schedule, index) =>
                      schedule[0] === "default" ? (
                        <div>{getDefaultSchedule(schedule, index)}</div>
                      ) : (
                        <div>
                          <div class="row">
                            <div class="col-sm-4 col-xs-4 col-md-5 col-lg-5 col-xl-6"></div>
                            <div class="col-sm-4 col-xs-4 col-md-4 col-lg-4 col-xl-3 schedule-div">
                              <p className={"schedule1_label"}>
                                {scheduleAuditsPage.SCHEDULE_LABEL +
                                  (index + 1)}
                              </p>
                              <div class="schedule-sub-div">
                                <p className={"scheduled-time"}>
                                  {schedule[4] + ":" + schedule[5]}
                                </p>
                                <p className={"scheduled-time-period"}>
                                  {schedule[8] ? "AM" : "PM"}
                                </p>
                                &nbsp; &nbsp;
                                <p className={"scheduled-timezone"}>
                                  {schedule[6]}
                                </p>
                                <button
                                  class={"edit-schedule-text"}
                                  onClick={() => handleEdit(schedule, index)}
                                >
                                  {"edit"}
                                </button>
                              </div>
                              <div className={"days-selected-div"}>
                                {schedule[7] &&
                                  schedule[7].map((day, index) => {
                                    return (
                                      <p className={"scheduled-timezone"}>
                                        {day}
                                      </p>
                                    );
                                  })}
                              </div>
                            </div>
                            <div class="col-sm-3 col-xs-3 col-md-4 col-lg-1 col-xl-1"></div>
                            <div class="col-sm-3 col-xs-3 col-md-4 col-lg-1 col-xl-1"></div>
                          </div>
                          {scheduleList.length - 1 === index ? null : (
                            <hr class="hr"></hr>
                          )}
                        </div>
                      )
                    )}
                </div>
                <div class="col-sm-3 col-xs-3 col-md-3 col-lg-3 col-xl-3 time-zone-div desktop-view">
                  <div class="separation-line"> </div>
                  <UpgradePlan
                    currentPlan={currentPlan}
                    currentPlanAuditCount={currentPlanAuditCount}
                    nextPlan={nextPlan}
                    nextPlanAuditCount={nextPlanAuditCount}
                    auditCountExceeded={auditCountExceeded}
                    handleUpgradeLevel={handleUpgradeLevel}
                    customPlan={customPlan}
                  ></UpgradePlan>
                </div>
              </div>
            </div>

            <div class="row row-style mobile-view">
              <div
                className={
                  auditCountExceeded
                    ? "upgrade-main-error-div"
                    : "upgrade-main-div" +
                    " " +
                    "col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12"
                }
              >
                <UpgradePlan
                  currentPlan={currentPlan}
                  currentPlanAuditCount={currentPlanAuditCount}
                  nextPlan={nextPlan}
                  nextPlanAuditCount={nextPlanAuditCount}
                  auditCountExceeded={auditCountExceeded}
                  handleUpgradeLevel={handleUpgradeLevel}
                  customPlan={customPlan}
                ></UpgradePlan>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      ) : (
        <PayoffPage
          accessToken={props.accessToken}
          tokenType={props.tokenType}
          expiry={props.expiry}
          uid={props.uid}
          client={props.client}
          websiteId={props.websiteId}
          nextPlan={nextPlan}
          nextPlanServiceId={nextPlanServiceId}
          svlEnabled={svlEnabled}
        ></PayoffPage>
      )}
    </>
  );
};

export default ScheduleOnboardingPage;
