/** @format */

import "./index.css";
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import AddUrlPage from "./../AddUrlPage/index";
import strings from "../../strings";
import Header from "../common/Header/index";
import Footer from "../common/Footer/index";
import { Skeleton } from "@material-ui/lab";

const {
  enterDomainPage,
  httpHeaderKeys,
  seoradarServiceLevels,
  httpMethods,
  httpHeaderValues,
  validDomainRegEx,
  noMsg,
} = strings;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    width: 134,
    padding: 0,
    marginTop: 14,
    marginBottom: 14,
  },
}))(Tooltip);

const EnterDomainPage = (props) => {
  const [domainUrl, setDomainUrl] = useState("");
  const [error, setError] = useState("");
  const [openAddUrl, setOpenAddUrl] = useState(false);
  const [websiteId, setWebsiteId] = useState("");
  const [domainBlocked, setDomainBlocked] = useState(false);
  const [domainNotValid, setDomainNotValid] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [monitorDomBtnDisabled, SetMonitorDomBtnDisabled] = useState(false);

  const goToRequestDemoPage = () => {
    window.location.href = "https://calendly.com/seoradar-clay/15min";
  };

  const urlChangeHandler = (e) => {
    let val = e.target.value;
    let pattern = new RegExp(validDomainRegEx, "i");

    let isValidUrl = !!pattern.test(val);

    if (val.length < 1 || !isValidUrl) {
      setError(enterDomainPage.VALID_DOMAIN_MSG);
      setDomainUrl(val);
    } else {
      setDomainUrl(val);
      setError(noMsg);
      setDomainNotValid(false);
    }
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    if (!error) {
      SetMonitorDomBtnDisabled(true);
      var myHeaders = new Headers();
      myHeaders.append(httpHeaderKeys.CONTENT_TYPE, httpHeaderValues.JSON_TYPE);
      myHeaders.append(httpHeaderKeys.ACCESS_TOKEN, props.accessToken);
      myHeaders.append(httpHeaderKeys.CLIENT, props.client);
      myHeaders.append(httpHeaderKeys.TOKEN_TYPE, props.tokenType);
      myHeaders.append(httpHeaderKeys.EXPIRY, props.expiry);
      myHeaders.append(httpHeaderKeys.UID, props.uid);

      var requestBody = JSON.stringify({ domain_url: domainUrl });

      var requestOptions = {
        method: httpMethods.POST,
        headers: myHeaders,
        body: requestBody,
      };

      const apiEndPoint = `${process.env.REACT_APP_API_URI}api/v2/websites`;

      fetch(apiEndPoint, requestOptions)
        .then((response) => {
          if (response.status === 403) {
            setDomainBlocked(true);
          } else if (response.status === 404) {
            setDomainNotValid(true);
          } else {
            return response.text();
          }
        })
        .then(async (result) => {
          if (result === undefined) { // means some error case
            SetMonitorDomBtnDisabled(false);
            return;
          }
          const resultJson = JSON.parse(result);
          const { website_id } = resultJson;
          if (website_id) {
            setWebsiteId(website_id);
            setOpenAddUrl(true);
          } else {
            setError(resultJson.errors.join(","));
          }
        })
        .catch((error) => {
          console.log(`Error msg: ${error.message}`);
          SetMonitorDomBtnDisabled(false);
        });
    }
  };

  return !openAddUrl ? (
    <div>
      <Header screenNumber={2}></Header>
      <div>
        <div className="enter-domain-text">
          <p>{enterDomainPage.TITLE}</p>
        </div>
        <div className="enter-domain-img-div">
          <picture>
            <source
              media="(min-width:769px)"
              srcset="/images/www-icon-mobile.png"
            ></source>
            {loaded ? null : (
              <Skeleton
                variant="rectangular"
                animation="wave"
                className={"www-icon-notloaded"}
                width={136}
                height={122}
              />
            )}
            <img
              alt="add domain"
              className={loaded ? "www-icon" : "display-none"}
              src="/images/www-icon-mobile.png"
              onLoad={() => setLoaded(true)}
            ></img>
          </picture>
        </div>
        <form
          id="website-url"
          className="domain-container"
          onSubmit={onSubmitHandler}
        >
          <div className="domain-label x">
            <p>{enterDomainPage.DOMAIN_LABEL}</p>
          </div>
          <div className="domain-input-parent y">
            <input
              className="domain-input padded-input"
              placeholder={enterDomainPage.domainPlaceHolder}
              onChange={urlChangeHandler}
              value={domainUrl}
            ></input>
            {(domainBlocked === true || domainNotValid === true) && (
              <p>
                <span className="error-text-md">
                  {
                    "Domain does not exist or is blocked. Please enter a different domain or check for typos."
                  }
                </span>
                <span className="error-text-md">
                  <button
                    type="button"
                    className={"schedule-for-tech-support"}
                    onClick={goToRequestDemoPage}
                  >
                    {"Schedule for Technical Support?"}
                  </button>
                </span>
              </p>
            )}
            {domainBlocked === false && domainNotValid === false && (
              <p className={"checkbox-error-text"}>{error}</p>
            )}
          </div>
          <div className="icon-container x">
            <LightTooltip title={enterDomainPage.TOOLTIP} placement="right">
              <img
                className="info-icon"
                src="/images/info_icon.png"
                alt="info-icon"
              ></img>
            </LightTooltip>
          </div>
        </form>
        <div className="monitor-domain-parent">
          <button
            disabled={monitorDomBtnDisabled}
            form="website-url"
            className="monitor-domain"
          >
            {enterDomainPage.ADD_DOMAIN_BTN_LABEL}
          </button>
        </div>
      </div>
      <Footer></Footer>
    </div>
  ) : (
    <AddUrlPage
      accessToken={props.accessToken}
      tokenType={props.tokenType}
      expiry={props.expiry}
      uid={props.uid}
      client={props.client}
      websiteId={websiteId}
      domainUrl={domainUrl}
      allowSubdomainUrls={props.allowSubdomainUrls}
      service_level={props.service_level}
      service_level_id={props.service_level_id}
    ></AddUrlPage>
  );
};

export default EnterDomainPage;
